import { reactive, ref } from "vue";

const DURATION = ref(2000)
const items = reactive([])

export default function useToast() {
  const toast = {
    success: (text, duration) => {
      init(text, duration, 'success')
    },
    error: (text, duration) => {
      init(text, duration, 'error')
    },
    warning: (text, duration) => {
      init(text, duration, 'warning')
    }
  }

  function init (text, duration, type) {
    const id = new Date()

    items.push({
      id,
      text,
      type
    })

    deleteFunction(id)

    if(duration) DURATION.value = duration
  }

  function deleteFunction (id) {
    setTimeout(() => {
      const index = items.findIndex(item => item.id === id)
      items.splice(index, 1)
    }, DURATION.value)
  }

  return {
    toast, items
  }
}