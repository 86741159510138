import api from "@/network/index.js";

export default {
    async addParticipantsToPlan(planUuid: string, userUuids: string[]) {

        return await api.post(
            `plans/${planUuid}/participants-multiple`,
            {
                uuids: userUuids,
            }
        );
    },
    async sendMessageToParticipants(uuid: string, payload: any) {

        return await api.post(
            `plans/${uuid}/participants/send-message`,
            payload
        );
    },
    async deleteFromPlan(uuid: string, participantUuids: string[]) {
        return await api.delete(
            `plans/${uuid}/participants`,
            {
                params: {
                    participantUuids
                }
            }
        );
    },
    async getAll(payload: any) {
        return await api.get(
            `plans/${payload.uuid}/participants`,
            {
                params: {
                    page: payload.params.page,
                    per_page: payload.params.perpage,
                    settings: payload.params.settings,
                },
            }
        );
    }
}