<template>
  <div class="assessment-plans__step">
    <div class="assessment-plans__step__header">
      <div class="assessment-plans__step__title">
        Шаг 1. Структура плана оценки
      </div>
      <mt-page-nav
        :next-btn-loading="nextRequestPending"
        @back="
          $router.push({
            name: 'AssessmentPlansList',
          })
        "
        @next="goToNextStep()"
      />
    </div>
    <div class="assessment-plans__step__body">
      <plan-common-info-form
        class="assessment-plans__step__fields"
        ref="planCommonInfoForm"
      />

      <div class="assessment-plans__step__tasks">
        <plan-tasks ref="planTasks" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapState } from 'vuex'
import mtPageNav from '@/components/composite/mtPageNav.vue'
import PlansAdminApi from '@/api/plans-admin.api.ts'
import PlanCommonInfoForm from '@/components/workspace/assessmentPlans/plans/plan/step1/PlanCommonInfoForm.vue'
import PlanTasks from '@/components/workspace/assessmentPlans/plans/plan/step1/PlanTasks.vue'

export default {
  components: {
    PlanTasks,
    PlanCommonInfoForm,
    mtPageNav,
  },
  data: () => ({
    nextRequestPending: false,
  }),
  computed: {
    ...mapGetters('assessmentPlans/plan', ['planEditdataExd']),
    ...mapState('assessmentPlans/plan', ['isLoading']),
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['fetchPlan']),
    ...mapActions('breadcrumbs', ['setBreadcrumbs']),

    async initBreadcrumbs() {
      if(!this.$route.meta.breadcrumbs) return;

      await this.setBreadcrumbs([
        ...this.$route.meta.breadcrumbs,
        { value: this.planEditdataExd.name },
      ])
    },

    async goToNextStep() {
      const isValid = await this.validateAll()
      if (!isValid) return

      this.nextRequestPending = true
      PlansAdminApi.update(this.planEditdataExd.uuid, this.planEditdataExd)
        .then(async (response) => {
          await this.fetchPlan({
            uuid: this.planEditdataExd.uuid,
            withoutLoading: false,
          })
          this.$router.push({
            name: 'AddEdit2',
            params: {
              planUuid: this.planEditdataExd.uuid,
            },
          })
        })
        .finally(() => {
          this.nextRequestPending = false
        })
    },

    async validateAll() {
      let valid = true
      const isValidForm = await this.$refs.planCommonInfoForm.validate()
      const isValidTasks = this.$refs.planTasks.validate()

      if (!isValidForm || !isValidTasks) {
        valid = false
      }

      return valid
    },
  },
  mounted() {
    this.initBreadcrumbs()
  },
}
</script>
