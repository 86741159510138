import api from "@/network";

export default {
    state: {
        administratorsSortConfig: {
            field: null,
            order: null,
        },

        branches: [],
        branchesBalance: {},

        manualDistributionStatus: false,
    },

    getters: {
        administratorsSortConfig(state) {
            return state.administratorsSortConfig;
        },

        branches(state) {
            return state.branches;
        },

        manualDistributionStatus(state) {
            return state.manualDistributionStatus;
        },

        branchesBalance(state) {
            return state.branchesBalance;
        },
    },

    actions: {
        async fetchBranches({ commit }) {
            const response = await api.get('admin/branch');

            commit('updateBranches', response?.data?.data);
            return response

        },

        async getBranches(context, payload) {
            let url = 'admin/branch';
            if (payload.page > 1) {
                url = `${ url }&page=${ payload.page }`

            }
            if (payload.perpage !== 0) {
                if (payload.page > 1) {
                    url = `${ url }&per_page=${ payload.perpage }`
                } else {
                    url = `${ url }?per_page=${ payload.perpage }`
                }
            }
            if (!!payload.settings) {
                url = `${ url }&settings=${ JSON.stringify(payload.settings) }`
            }
            return await api.get(url);
        },


        /**
         *
         * @param { object } payload
         * {
         *    @param { string } uuid : "e0dde03b-becf-41f9-8e74-92fa224cb841",
         * }
         */
        async deleteBranches({ commit, state }, payload = {}) {


            await api.delete(`admin/branch/${ payload.uuid }`);

            let branches = state.branches.filter((branch) => {
                return branch.uuid !== payload.uuid
            });

            commit('updateBranches', branches);
        },

        async fetchManualDistributionStatus({ commit }) {

            let result = await api.get('admin/branch/dist_balance')
            commit('updateManualDistributionStatus', result.data.dist_balance);
            return result.data.dist_balance

        },

        async setManualDistributionStatus({ commit }) {


            api.put('admin/branch/dist_balance')
                .then(
                    (response) => {

                        commit('updateManualDistributionStatus', response.data.dist_balance);
                    }
                )
                .catch(
                    (error) => {

                    }
                )
        },


    },

    mutations: {
        updateBranches(state, branches) {
            state.branches = branches;
        },

        updateManualDistributionStatus(state, manualDistributionStatus) {
            state.manualDistributionStatus = manualDistributionStatus;
        },

        updateBranchesBalance(state, branchesBalance) {
            state.branchesBalance = branchesBalance;
        },
    },
}