<template>
  <component
      :is="componentType"
      :class="[
          'mt-button',
          `mt-button_${size}`,
          `mt-button_${icon ? 'only-icon' : 'default'}`,
          {
            'mt-button_loading': loading
          },
          color ? `mt-button_${color}` : ''
      ]"
      :disabled="disabled || loading"
      :to="to"
      :href="href"
  >

    <span
        class="mt-button__icon"
        v-if="hasSlot('icon')"
    >
      <slot name="icon" />
    </span>
    <span
      :class="[
        'mt-button__content',
        {
          'mt-button__content_hide': loading
        }
      ]"
    >
      <slot/>
    </span>
    <app-three-dots-preloader
        class="mt-button__loading mt-button__loading_default"
        v-if="loading && !icon"
    />
    <app-dots-in-circle-preloader
        class="mt-button__loading mt-button__loading_only-icon"
        v-if="loading && icon"
    />
  </component>
</template>

<script>
import mtIcon from '@/components/UI/mtIcon/mtIcon.vue';
import { resolveComponent } from "vue";
import AppThreeDotsPreloader from "@/components/UI/AppThreeDotsPreloader.vue";
import AppDotsInCirclePreloader from "@/components/UI/AppDotsInCirclePreloader.vue";

export default {
  name: 'mtButton',
  components: {
    AppDotsInCirclePreloader,
    AppThreeDotsPreloader,
    mtIcon
  },
  props: {
    icon: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      default: (props) => props.icon ? 'light-gray' : 'green',
      type: String
    },
    size: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'small', 'large'].includes(value)
      }
    },
    to: {
      type: [String, Object],
      default: () => null
    },
    href: {
      type: String,
      default: null
    },
  },
  computed: {
    componentType() {
      if(this.to) return resolveComponent('router-link');

      if(this.href) return 'a';

      else return 'button';
    },

  },
  methods: {
    hasSlot(name) {
      return !!this.$slots[name]
    }
  }
}

</script>

<style
    lang="scss"
    src="./style.scss"
/>