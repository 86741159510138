<template>
  <custom-assessment :on-mount="runOnMount" />
</template>

<script>
import { mapActions } from 'vuex';
import CustomAssessment from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessment.vue";

export default {
  name: "RespondentCustomAssessment",
  components: {CustomAssessment},
  methods: {
    ...mapActions('assessmentPlans/plan/task', ['startCustomAssessmentSession']),
    async runOnMount() {
      await this.startCustomAssessmentSession({
        planUuid: this.$route.params.planUuid,
        taskUuid: this.$route.params.taskUuid,
      });
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />