<template>
  <app-form-block
    :class="[
      'app-input',
      {
        'app-input_disabled': disabled,
        'app-input_error': errors.length > 0 || errors === true,
      },
    ]"
    :half="half"
    :errors="Array.isArray(errors) ? errors : []"
    :active="isInputActive"
  >
    <template #label>
      {{ label }}
      <slot name="label" />
    </template>
    <input
      ref="input"
      v-model="computedModelValue"
      :disabled="disabled"
      :readonly="readonly"
      :type="type"
      :class="[
        'app-input__field',
        {
          'app-input__field--active': isInputActive,
          'app-input__field--small': small,
        },
      ]"
      :placeholder="placeholder"
      :style="inputStyle"
      :autocomplete="autocomplete"
      @focusin="focusIn"
      @focusout="focusOut"
      @keypress="keypress"
      @animationstart="checkAnimation"
    />

    <div v-if="$slots.append" ref="append" class="app-input__append">
      <slot name="append" />
    </div>
  </app-form-block>
</template>

<script>
import AppFormBlock from '@/components/UI/AppFormBlock/AppFormBlock.vue'

export default {
  name: 'AppInput',
  components: { AppFormBlock },
  props: {
    disabled: Boolean,
    errors: {
      type: [Array, Boolean],
      default: () => [],
    },
    half: {
      type: Boolean,
    },
    label: String,
    placeholder: String,
    type: String,
    modelValue: [String, Number],
    small: Boolean,
    value: String,
    height: String,
    readonly: Boolean,
    autocomplete: String,
    keypress: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isInputActive: false,

      inputStyle: {},
    }
  },
  computed: {
    computedModelValue: {
      get() {
        if (this.value) return this.value

        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    checkAnimation(e) {
      if (e.animationName === 'onAutoFillStart') {
        this.focusIn();
      }
    },
    focusIn() {
      this.isInputActive = true
      this.$emit('focus')
    },
    focusOut() {
      if (this.computedModelValue) return

      this.isInputActive = false
    },
  },
  mounted() {
    this.isInputActive = !!this.computedModelValue
    this.$nextTick(() => {
      setTimeout(() => {
        if (!!this.computedModelValue || !this.$refs.input) return
        this.isInputActive =
          window
            .getComputedStyle(this.$refs.input)
            .getPropertyValue('appearance') === 'menulist-button'
      }, 0)
    })

    const appendWidth = this.$refs.append?.offsetWidth
    if (appendWidth) this.inputStyle.paddingRight = appendWidth + 'px'
    if (this.height) this.inputStyle.height = this.height
  },
  watch: {
    computedModelValue: {
      handler(newValue) {
        if (newValue) this.isInputActive = !!this.computedModelValue
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" src="./AppInput.scss" />
