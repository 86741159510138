<script setup lang="ts">
import AppFormBuilder from "@/components/UI/AppFormBuilder.vue";
import genders from "@/constants/genders.js";
import AuthApi from "@/api/auth.api.ts";
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import {format} from "date-fns";
import { rules } from "@/services/validate.ts";

interface Props {
  handler: (payload) => Promise<void>;
  handlerButtonText?: string;
  disableButton?: boolean;
  half?: boolean;
  user?: any;
  moreFields?: {
    [key: string]: any;
  }
}

const props = defineProps<Props>();

const emits = defineEmits(['change']);

const fields = reactive({
  firstname: {
    type: "string",
    modelValue: props.user?.firstname ?? null,
    key: 'firstname',
    bind: {
      label: "Имя *",
      half: props.half,
    },
    rules: {
      required: rules.required
    }
  },
  lastname: {
    type: "string",
    required: true,
    modelValue: props.user?.lastname ?? null,
    key: 'lastname',
    bind: {
      label: "Фамилия *",
      half: props.half,
    },
    rules: {
      required: rules.required
    }
  },
  middlename: {
    type: "string",
    modelValue: props.user?.middlename ?? null,
    key: 'middlename',
    bind: {
      label: "Отчество",
      half: props.half,
    },
  },

  birthday: {
    modelValue: props.user?.birthday ?? null,
    bind: {
      label: "Дата рождения",
      half: props.half,
      maxDate: format(new Date(), 'd.m.Y')
    },
    type: "date",
    key: 'birthday',
    value: null,
  },
  gender: {
    name: "Пол",
    type: "radio",
    key: 'gender',
    modelValue: props.user?.gender ?? null,
    bind: {
      label: "Пол",
      half: props.half,
      items: genders.map(({ slug, name }) => ({ name, uuid: slug })),
      itemText: 'name',
      itemValue: 'uuid',
    },
  },
})

if(props.moreFields) {
  Object.keys(props.moreFields).forEach(key => {
    fields[key] = props.moreFields[key];
  })
}
async function getFormFields() {
  const { data } = await AuthApi.getUserAdditionalForm();

  data.data.forEach(field => {
    const foundItem = props.user?.formSettings.find(item => item.uuid === field.uuid);

    fields[field.name] = {
      modelValue: foundItem ? foundItem.value : '',
      key: field.name,
      ...field,
      group: 'additionalFields',
      bind: {
        label: `${field.name} ${field.required ? '*' : ''}`,
        half: props.half,
        items: field.variants,
        itemText: 'name',
        itemValue: 'uuid',
      },
    }

    if(field.required) {
      fields[field.name].rules = {
        required: rules.required
      }
    }
  });
}

getFormFields();

async function submit() {
  const additionalFields = Object.values(fields).filter((field) => field.group === 'additionalFields');

  return await props.handler({
    firstname: fields.firstname.modelValue,
    lastname: fields.lastname.modelValue,
    middlename: fields.middlename.modelValue,
    birthday: fields.birthday.modelValue,
    gender: fields.gender.modelValue,
    role: fields.role?.modelValue,
    email: fields.email?.modelValue,
    formSettings: additionalFields.map(field => ({
      uuid: field.uuid,
      value: field.modelValue,
      type: field.type
    }))
  })
}

defineExpose({
  fields: fields
})

watch(fields, () => {
  emits('change', fields);
})
</script>

<template>
  <app-form-builder
    v-model="fields"
    :handler="submit"
    :handler-button-text="handlerButtonText"
    :disable-button="disableButton"
  >
    <template #prepend-form>
      <app-input
          v-if="user?.email"
          label="Email *"
          type="email"
          :value="user.email"
          disabled
      />
    </template>
    <template #prepend-buttons>
      <slot name="prepend-buttons" />
    </template>
    <template #append-buttons>
      <slot name="append-buttons" />
    </template>
  </app-form-builder>
</template>
