import api from "@/network";
import externalApi from "@/network/external";

export default {
  namespaced: true,

  state: {
    results: [],
  },

  getters: {
    results(state) {
      return state.results;
    }
  },

  actions: {
    /* FETCHERS */
    async fetchResults({ commit }, payload = {}) {
      return await api.get(`results`, {
        params: {
          page: payload.page,
          per_page: payload.perpage,
          settings: payload.settings,
        },
      });
    },
    async fetchPlansForReplications({ }, { user, maintest, result, }) { // FIXME must move
      return await api.get(
          `results/plans`,
          {
            params: {
              user,
              maintest,
              result,
            },
          }
      );
    },

    async addTags({ commit }, payload = {}) {
      return await api.put('results/tags-attach', payload.tags);
    },
    async exportResultsReport({ }, payload) {
      return await api.post('export/results', payload);
    },
    async downloadResultsReport({ }, { reportLink, type, isGroup, fileName }) {

      if (isGroup) {
        window.open(reportLink, "_blank");
      } else {
        const response = await externalApi.get(`${reportLink}`, { responseType: 'blob', });

        if (response.data.size) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');

          link.href = url;

          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        } else {
          this.$toast.error('Отчёт не найден')
        }
      }
    },

    /* SERVICE ACTIONS */
    async deleteResults({ }, { results }) {
      return await api.delete('results', { data: results });
    },
    async deleteResultsForceAction({ }, { results }) {
      if (!results) return;

      return await api.delete('results/force-delete', { data: results });
    },
    async restoreResultsAction({ }, { results }) {
      if (!results) return;

      return await api.put('results/restore', results);
    },
    async reprocessResults({ }, { sessions }) {
      return await api.put('results/reprocess', { data: sessions });
    },
    async resultsPlansAttach({ }, { uuid, plans }) {
      return await api.put('results/plans-attach', [{ uuid, plans }]);
    },
  },

  mutations: {
    async updateResults(state, plans) {


      state.plans = plans;
    },
  },
}