import api from "@/network";
import {AxiosResponse} from "axios";

type DeleteDto = {
    planUuid: string;
    testUuid: string;
}
export default {
    async upload(file: File): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await api.post<string>('/temporary-files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    },
    async get(uuids: string[]): Promise<any[]> {
        return await api.get('/temporary-files', {
            params: {
                uuids
            }
        });
    }
}