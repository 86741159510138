<template>
  <div>
    <app-server-data-table
      ref="table"
      :headers="cols"
      v-model:params="params"
      v-model:selected-items="selectedParticipants"
      checkable
      searchable
      sortable
      filterable
      colsable
      with-pagination
      save-on-url
      :items-provider="getParticipantsList"
      class="step4-table"
    >
      <template #title>Шаг 4. Участники плана оценки</template>
      <template #header-right>
        <mt-page-nav
          :next-btn-loading="next_preloader"
          @back="onBackClicked()"
          @next="onNextClicked()"
        >
          <template #back> Назад </template>
          <template #next> Далее </template>
        </mt-page-nav>
      </template>
      <template #header-actions>
        <div class="assessment-plans__step__body__actions">
          <mt-button
            class="assessment-plans-step4__add"
            @click="openAddParticipantDialog()"
          >
            <plus-icon />
            <span>Добавить</span>
          </mt-button>

          <div class="d-flex">
            <app-tooltip>
              <template #activator>
                <mt-button
                  icon
                  class="mr-1"
                  @click="importDialogActivator = true"
                >
                  <import-icon />
                </mt-button>
              </template>
              Импорт
            </app-tooltip>
            <app-tooltip>
              <template #activator>
                <mt-button
                  :to="{
                    name: 'ExpRespCorrespondence',
                    params: {
                      uuid: this.$route.params.planUuid,
                    },
                    query: {
                      ...this.$route.query,
                    },
                  }"
                  icon
                >
                  <users-icon />
                </mt-button>
              </template>

              Назначить эксперту респондентов для проведения оценки
            </app-tooltip>
          </div>
        </div>
      </template>

      <template #full_name="{ item }">
        {{ item.full_name }}
      </template>
      <template #role="{ item }">
        {{ item.role?.name }}
      </template>
      <template #status="{ item }">
        <user-status-chip :value="item.status" />
      </template>
      <template #progress="{ item }">
        <div v-if="item.progress !== undefined">
          <span class="progress_value d-flex justify-center">
            {{ item.progress + '%' }}
          </span>
          <mt-linear-progress :progress="item.progress" />
        </div>
      </template>

      <template #actions="{ item }">
        <div class="d-flex">
          <mt-button
            icon
            class="mr-2"
            :to="{
              name: 'UserSingle',
              params: {
                id: item.uuid,
              },
            }"
          >
            <pencil-icon />
          </mt-button>
          <app-tooltip top>
            <template #activator>
              <mt-button
                icon
                class="mr-2"
                @click.stop="openSendMessageDialog([item])"
              >
                <message-icon />
              </mt-button>
            </template>
            Отправить сообщение участнику
          </app-tooltip>
          <mt-button
            color="light-red"
            icon
            @click.stop="openDeleteParticipantDialog(item)"
          >
            <close-icon />
          </mt-button>
        </div>
      </template>

      <template #footer-actions>
        <div class="assessment-plans-step4__actions">
          <mt-button
            class="mr-1"
            :loading="massDeleteRequestPending"
            color="green"
            @click="openSendMessageDialog(selectedParticipants)"
            :disabled="!selectedParticipants.length"
            size="small"
          >
            Отправка сообщения
          </mt-button>

          <mt-button
            :loading="massDeleteRequestPending"
            color="green"
            size="small"
            @click="openMassDeleteParticipantsDialog()"
            :disabled="!selectedParticipants.length"
          >
            <template #default>
              <slot name="next">Удалить</slot>
            </template>
          </mt-button>
        </div>
      </template>
    </app-server-data-table>

    <add-participants-to-plan-dialog
      v-model="allParticipantsModal"
      @success="this.$refs.table.getItems()"
    />

    <teleport to="body">
      <confirmation-dialog
        v-model="deleteSingleParticipantDialog"
        :title="`Удалить участника ${deletingUser?.full_name} из данного плана?`"
        :handler="deleteSingleParticipant"
      />

      <confirmation-dialog
        v-model="massDeleteParticipantsDialog"
        :handler="deleteParticipants"
      >
        <template #title>
          Удалить участников из данного плана? <br />
          Количество: {{ selectedParticipants?.length }}
        </template>
      </confirmation-dialog>
    </teleport>

    <import-dialog
      v-model="importDialogActivator"
      @success="$refs.table.getItems()"
    />

    <send-message-dialog
      ref="sendMessageDialogRef"
      :api-provider="sendMessageToParticipants"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import validation from '@/plugins/_validation.js'
import mtPageNav from '@/components/composite/mtPageNav.vue'
import mtTable from '@/components/UI/mtTable/mtTable.vue'
import mtPagination from '@/components/UI/mtPagination/mtPagination.vue'
import mtFilters from '@/components/UI/mtFilters/mtFilters.vue'
import mtLinearProgress from '@/components/UI/mtLinearProgress/mtLinearProgress.vue'
import mtIcon from '@/components/UI/mtIcon/mtIcon.vue'
import mtButton from '@/components/UI/mtButton/index.vue'
import mtSvg from '@/components/UI/mtSvg/index.vue'
import ConfirmationDialog from '@/components/composite/confirmationDialog/confirmationDialog.vue'
import InfoBlock from '@/components/workspace/InfoBlock.vue'
import AppTooltip from '@/components/UI/AppTooltip/AppTooltip.vue'
import AppCheckbox from '@/components/UI/AppCheckbox/AppCheckbox.vue'
import mtCheckbox from '@/components/UI/mtCheckbox/index.vue'
import ImportIcon from '@/components/icons/ImportIcon.vue'
import MessageIcon from '@/components/icons/MessageIcon.vue'
import MtDialog from '@/components/UI/mtDialog/mtDialog.vue'
import AppChip from '@/components/UI/AppChips/AppChip.vue'
import AppTextarea from '@/components/UI/AppTextarea/AppTextarea.vue'
import MtChip from '@/components/UI/mtChip/index.vue'
import ParticipantApi from '@/api/participant.api.ts'
import AuthApi from '@/api/auth.api.ts'
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import ImportDialog from '@/components/composite/ImportDialog.vue'
import UserStatusChip from '@/components/workspace/UserStatusChip.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import AppServerDataTable from '@/components/UI/AppServerDataTable/AppServerDataTable.vue'
import AddParticipantsToPlanDialog from '@/components/workspace/assessmentPlans/plans/plan/step4/AddParticipantsToPlanDialog.vue'
import UsersIcon from '@/components/icons/UsersIcon.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue'
import DatabaseImportIcon from '@/components/icons/DatabaseImportIcon.vue'
import PencilIcon from '@/components/icons/PencilIcon.vue'
import SendMessageDialog from '@/components/workspace/assessmentPlans/plans/plan/step4/SendMessageDialog.vue'

export default {
  components: {
    PencilIcon,
    DatabaseImportIcon,
    PlusIcon,
    UsersIcon,
    AddParticipantsToPlanDialog,
    AppServerDataTable,
    CloseIcon,
    TrashIcon,
    UserStatusChip,
    ImportDialog,
    AppInput,
    MtChip,
    AppTextarea,
    AppChip,
    MtDialog,
    MessageIcon,
    ImportIcon,
    mtCheckbox,
    AppCheckbox,
    AppTooltip,
    InfoBlock,
    ConfirmationDialog,
    mtPageNav,
    mtTable,
    mtPagination,
    mtFilters,
    mtLinearProgress,
    mtIcon,
    mtButton,
    mtSvg,
    SendMessageDialog,
  },
  data() {
    return {
      importDialogActivator: false,
      max: 0,
      maxPercent: 100,
      next_preloader: false,
      cols: [
        {
          title: 'ФИО',
          stub: 'full_name',
          visible: true,
          filter: 'string',
          filterable: true,
          sortable: true,
          showable: false,
        },
        {
          title: 'E-mail',
          stub: 'email',
          visible: true,
          filter: 'string',
          filterable: true,
          sortable: true,
          showable: true,
        },

        {
          title: 'Роль',
          stub: 'role',
          visible: true,
          filter: 'string',
          filterable: true,
          sortable: true,
          showable: true,
        },

        {
          title: 'Статус',
          stub: 'status',
          visible: true,
          filter: 'list',
          filterable: true,
          sortable: true,
          showable: true,
        },

        {
          title: 'Прогресс',
          stub: 'progress',
          visible: true,
          filter: 'string',
          filterable: true,
          sortable: true,
          showable: true,
        },
      ],

      allParticipants: [],

      /* STATES */
      massDeleteRequestPending: false,
      allParticipantsModal: false,

      /* REQUEST PARAMS */
      isLoaded: false,

      sort: {
        field: 'full_name',
        order: 'asc',
      },
      deletingUser: null,
      deleteSingleParticipantDialog: false,
      massDeleteParticipantsDialog: false,
      formSettings: [],
      params: {},
      selectedParticipants: [],
    }
  },
  methods: {
    openMassDeleteParticipantsDialog() {
      this.massDeleteParticipantsDialog = true
    },
    async sendMessageToParticipants(params) {
      return await ParticipantApi.sendMessageToParticipants(
        this.$route.params.planUuid,
        params,
      )
    },

    openSendMessageDialog(participants) {
      this.$refs.sendMessageDialogRef.openModal(participants)
    },

    async getParticipantsList() {
      return await ParticipantApi.getAll({
        uuid: this.$route.params.planUuid,
        params: this.params,
      })
    },

    openAddParticipantDialog() {
      this.allParticipantsModal = true
    },

    onBackClicked() {
      this.$router.push({
        name: 'AddEdit3',
      })
    },

    onNextClicked() {
      this.$router.push({ name: 'AssessmentPlansList' })
    },

    openDeleteParticipantDialog(participant) {
      this.deletingUser = participant
      this.deleteSingleParticipantDialog = true
    },

    async deleteSingleParticipant() {
      return await ParticipantApi.deleteFromPlan(this.$route.params.planUuid, [
        this.deletingUser.uuid,
      ]).then(() => {
        this.$refs.table.getItems()
      })
    },

    async deleteParticipants() {
      this.massDeleteRequestPending = true

      return await ParticipantApi.deleteFromPlan(
        this.$route.params.planUuid,
        this.selectedParticipants.map((participant) => participant.uuid),
      )
        .then((response) => {
          this.$refs.table.getItems()
          this.selectedParticipants = []
        })
        .finally(() => {
          this.massDeleteRequestPending = false
        })
    },
  },
}
</script>

<style lang="scss">
.send-message-modal {
  &__title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }

  &__selected-users {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;

    label {
      margin-right: 4px;
    }

    .mt-chip {
      margin-top: 4px;
      margin-right: 4px;
    }
  }
}

.assessment-plans-step4 {
  &__title {
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
  }

  &__add {
    background-color: #00788c;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 4px 12px;
    cursor: pointer;
    margin-right: 20px;

    @include max-md {
      margin-bottom: 20px;
    }
  }

  &__export,
  &__exp-resp {
    margin-right: 10px;
    background: #f0f1f7;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
      background: #d7d7dd;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &-table {
    //min-width: 970px;
    width: 100%;

    &__actions-header {
      width: 100%;
      padding: 30px 30px 0px 15px;
    }

    &__actions-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__header {
    &-cell {
      padding: 12px 30px;
      vertical-align: middle;
      text-align: center;

      &_first {
        text-align: left;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    &-item {
      transition: all 0.2s ease;
      max-height: 24px !important;

      &:first-child {
        margin-left: 0;
      }

      &_active {
        background: #00788c !important;
        color: #ffffff !important;
        cursor: pointer !important;

        &:active {
          background: #066a7e !important;
        }
      }
    }

    &_delete {
      min-width: 83px;
    }

    &_switch {
      min-width: 89px;
    }
  }
}
</style>