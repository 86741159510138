<template>
  <svg fill="currentColor" height="48px" width="48px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 612 612" xml:space="preserve">
<g>
	<g>
		<g>
			<g>
				<path d="M577.661,612H34.339c-10.358,0-18.751-8.396-18.751-18.751V18.751C15.588,8.396,23.98,0,34.339,0h543.322
					c10.355,0,18.751,8.396,18.751,18.751v574.497C596.412,603.604,588.016,612,577.661,612z M53.09,574.497h505.82V37.502H53.09
					V574.497z"/>
			</g>
      <g>
				<path d="M476.951,157.596H135.047c-10.355,0-18.751-8.393-18.751-18.751c0-10.355,8.396-18.751,18.751-18.751h341.905
					c10.355,0,18.751,8.396,18.751,18.751C495.702,149.204,487.307,157.596,476.951,157.596z"/>
			</g>
      <g>
				<path d="M476.951,269.033H135.047c-10.355,0-18.751-8.393-18.751-18.751c0-10.355,8.396-18.751,18.751-18.751h341.905
					c10.355,0,18.751,8.396,18.751,18.751C495.702,260.641,487.307,269.033,476.951,269.033z"/>
			</g>
      <g>
				<path d="M476.951,380.469H135.047c-10.355,0-18.751-8.393-18.751-18.751c0-10.355,8.396-18.751,18.751-18.751h341.905
					c10.355,0,18.751,8.396,18.751,18.751C495.702,372.076,487.307,380.469,476.951,380.469z"/>
			</g>
      <g>
				<path d="M278.154,491.906H135.047c-10.355,0-18.751-8.394-18.751-18.751c0-10.355,8.396-18.751,18.751-18.751h143.106
					c10.355,0,18.751,8.396,18.751,18.751C296.905,483.512,288.509,491.906,278.154,491.906z"/>
			</g>
		</g>
	</g>
</g>
</svg>
</template>
<script setup lang="ts">
</script>