<template>
  <app-steps :steps="steps"/>

  <mt-loading-animation-linear v-if="!isLoaded" />

  <router-view v-else v-slot="{ Component }">
    <component :is="Component" />
  </router-view>

</template>

<script>

import { mapActions, mapGetters } from "vuex";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";
import AppSteps from "@/components/UI/AppSteps/AppSteps.vue";

export default {
  name: 'planAdmin',
  components: {AppSteps, MtLoadingAnimationLinear },
  data() {
    return {
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters('assessmentPlans/plan',["planEditdataExd"]),
    steps() {
      return [
        {
          name: 'Структура плана оценки',
          to: 'AddEdit1',
        },
        {
          name: 'Настройка параметров оценки',
          to: 'AddEdit2',
        },
        {
          name: 'Настройка уровней интерпретации',
          to: 'AddEdit3',
        },
        {
          name: 'Участники плана оценки',
          to: 'AddEdit4',
        }
      ]
    }

  },
  methods: {
    ...mapActions('breadcrumbs', ['setBreadcrumbs']),
    ...mapActions('assessmentPlans/plan', ['fetchPlan']),

    async initBreadcrumbs() {
      if(!this.$route.meta.breadcrumbs) return;

      await this.setBreadcrumbs([
        ...this.$route.meta.breadcrumbs,
        { value: this.planEditdataExd.name }
      ])
    }
  },
  mounted() {
    this.fetchPlan({ uuid: this.$route.params.planUuid })
      .then(() => {
        this.isLoaded = true;
      })

    if(this.$route.name === 'ExpRespAssessmentPlanSingle') {
      this.$router.push({
        name: 'AddEdit1',
        params: {
          uuid: this.$route.params.planUuid
        }
      })
    }
  },
  watch: {
    '$route': {
      handler(newValue) {
        newValue.matched.forEach(item => {
          if(item.name === "ExpRespAssessmentPlanSingle") this.initBreadcrumbs();
        })
      },
      deep: true
    }
  }
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>