<template>
  <div
      class="mt-select-multiple"
      :class="[{ 'mt-select-multiple_opened': showList }, { 'mt-select-multiple_error': errorState }, `mt-select-multiple__theme-${theme}`]"
      v-click-outside="vcoSettings"
  >
    <div class="mt-select-multiple__wrapper">
      <div
          class="mt-select-multiple__selected"
          :class="[{'mt-select-multiple__selected_disabled': disabled},]"
          @click="toggle"
      >
        <span>{{ getSelectedOptions() }}</span>
        <mt-icon
            class="mt-select-multiple__arrow"
            v-show="!disabled"
            name="arrow-down"
            :fill="!errorState ? '#00788C' : 'rgba(220, 0, 0, .3)'"
        />
      </div>
      <div class="mt-select-multiple__actions">
        <mt-button
            class="mt-select-multiple__select-btn"
            @click="selectAll"
        >
          <div class="mt-select-multiple__select-btn--title">Выбрать все</div>
        </mt-button>
        <mt-button
            class="mt-select-multiple__select-btn"
            @click="resetSelect"
        >
          <div class="mt-select-multiple__select-btn--title">снять все</div>
        </mt-button>
      </div>
      <div class="mt-select-multiple__list">
        <div
            class="mt-select-multiple__list-item"
            v-for="(option, index) in options"
            @click.self="selectOption(index)"
        >
          <mt-checkbox
              class="mt-select-multiple__list-item--checkbox"
              v-model="option.selected"
              disabled
              @click="selectOption(index)"
          />
          <div
              class="mt-select-multiple__list-item--title"
              @click.self="selectOption(index)"
          >
            {{ option.value || option.name || option }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mtIcon from '@/components/UI/mtIcon/mtIcon.vue';
import mtCheckbox from '@/components/UI/mtCheckbox/index.vue';
import mtButton from "@/components/UI/mtButton/index.vue";

export default {
  components: {
    mtIcon,
    mtCheckbox,
    mtButton,
  },

  props: {
    modelValue: {
      type: [String, Number, Object],
      default: null
    },
    items: {
      type: [Array, Object],
      default: () => []
    },
    hasError: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showList: false,
      errorState: this.hasError,
      vcoSettings: {
        handler: this.hide,
        events: ['mousedown'],
        isActive: true,
        detectIFrame: true,
        capture: false
      },
      focused: false,
      options: [],
    }
  },
  computed: {
    value() {
      if (this.modelValue !== null) return this.modelValue.name || this.modelValue
      return this.modelValue
    },
    selectedOptions() {
      return this.options.filter(option => option.selected);
    },
  },

  watch: {
    hasError(newV) {
      this.errorState = newV
    },
    items(newV) {
      this.parseOptions({ items: this.items, });
    },
  },
  methods: {
    /* GETTERS */
    getSelectedOptions() {
      if (!this.selectedOptions.length) {
        return 'Выбрать шкалы';
      }

      return `
        ${this.selectedOptions[0].name}
        ${this.selectedOptions.length > 1 ? ' + ' + (this.selectedOptions.length - 1) : ''}
      `;
    },

    /* SETTERS */
    /* HANDLERS */
    toggle() {
      if (this.disabled) return;

      this.focused = true;
      this.showList = !this.showList;
      this.errorState = false;
      this.$emit('focus');
    },
    hide() {
      this.showList = false
      if (this.focused) {
        this.focused = false
        this.$emit('blur')
      }
    },
    async selectOption(index) {
      this.options[index].selected = !this.options[index].selected;
      this.updateEvent();
    },
    async selectAll() {
      await this.options.forEach(
        option => option.selected = true
      );
      this.updateEvent();
    },
    async resetSelect() {
      await this.options.forEach(
        option => option.selected = false
      );
      this.updateEvent();
    },

    /* HELPERS */
    parseOptions({ items, }) {
      return this.options = items;
    },
    parseSelectedOptions({ options, }) {
      return options.filter(option => option.selected);
    },
    async updateEvent() {
      const SELECTED_OPTIONS = this.parseSelectedOptions({
        options: this.options,
      });

      this.$emit('update', SELECTED_OPTIONS);
    },
  },

  created() {
    this.parseOptions({ items: this.items, });
  },
  mounted() { },
}
</script>

<style lang="scss">
.mt-select-multiple {
  position: relative;
  height: 56px;
  min-width: 362px;
  cursor: pointer;

  &__wrapper {
    overflow: hidden;
    height: 56px;
    background: #FFFFFF;
    border-radius: 5px;
    position: relative;
    border: 1.5px solid #DFE0EB;
  }

  &__selected {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #252733;
    padding: 0 15px;
    border-bottom: 1.5px solid #DFE0EB;

    &_disabled {
      &>span {
        color: #DFE0EB;
      }
    }

    &>span {
      user-select: none;
    }
  }

  &__list {
    z-index: 10000;
    width: 100%;
    margin-top: 15px;

    &-item {
      height: 44px;
      display: flex;
      align-items: center;
      color: #7C869D;
      padding: 0 15px;
      user-select: none;

      &:hover {
        cursor: pointer;
        color: #677086;
        background-color: rgba(0, 0, 0, .03);
      }

      &--title {
        margin-left: 12px;
      }
    }
  }

  & .mt-select-multiple__arrow {
    transition: all ease-in-out .2s;
  }

  &_opened {
    z-index: 10000;
    position: relative;

    &>.mt-select-multiple__wrapper {
      height: auto;
      position: static;
      box-shadow: 0px 6px 5px rgba(124, 124, 124, 0.25);
      overflow: visible !important;
    }

    & .mt-select-multiple__arrow {
      transform: rotate(-180deg);
    }
  }

  &_error {
    &>.mt-select-multiple__wrapper {
      background-color: rgba(255, 0, 0, .3);
      border-color: rgba(255, 0, 0, .3) !important;
      box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 0.3);
    }
  }

  &__theme {
    &-border {
      & .mt-select-multiple__wrapper {
        background-color: #fff;
        border: solid 1px #C2C8D0;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    margin-left: 15px;
  }

  &__select-btn {
    width: max-content !important;
    height: 33px !important;
    border-radius: 8px !important;
    background: #E6F2F4 !important;
    margin-top: 15px;
    padding: 0 10px;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }

    &.mt-button_active {
      &:active {
        background: #cde3e7 !important;
      }
    }

    &--title {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #00788C;
      white-space: nowrap;
      user-select: none;
    }
  }
}
</style>