<template>
  <component :is="componentBasedOnRole" />
</template>

<script>
import { mapGetters } from 'vuex';
import mtDialog from '@/components/UI/mtDialog/mtDialog.vue';
import ExpertCustomAssessment
  from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/ExpertCustomAssessment.vue";
import RespondentCustomAssessment
  from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/RespondentCustomAssessment.vue";

export default {
  components: {
    mtDialog,
  },

  computed: {
    ...mapGetters(['authUserRoleSlug']),
    componentBasedOnRole () {
      if(['expert', 'inspector'].includes(this.authUserRoleSlug)) {
        return ExpertCustomAssessment
      }

      if(this.authUserRoleSlug === 'respondent') {
        return RespondentCustomAssessment
      }
    }
  }
}
</script>