<template>
  <div class="mt-breadcrumbs" :key="component">
    <span
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
      class="mt-breadcrumbs__crumb"
      @click="routeTo({ breadcrumb })"
    >
      {{ breadcrumb.value }}
    </span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {deepClone} from "@/services/utils.js";

export default {
  name: 'mtBreadcrumbs',
  data: () => ({
    component: 0,
  }),
  computed: {
    ...mapGetters('breadcrumbs', ['breadcrumbs']),
  },
  methods: {
    routeTo({ breadcrumb }) {
      if (breadcrumb.link) {
        this.$router.push({
          name: breadcrumb.link,
          params: breadcrumb.params,
          query: breadcrumb.query,
        })
      }
    },
  },
  watch: {
    breadcrumbs(val) {
      if (this.$route.meta.title) {
        document.title = this.$route.meta.title

        return
      }

      // Reverse breadcrumbs to show them in the  order, when child is first
      // also add '-' between breadcrumbs
      // and set document title the result
      const reversedBreadcrumbs = deepClone(val).reverse()
      document.title = reversedBreadcrumbs
          .reduce(
              (acc, item, index, array) =>
                  acc + item.value + (index < array.length - 1 ? ' - ' : ''),
              '',
          )
    },
  },
}
</script>

<style lang="scss">
.mt-breadcrumbs {
  max-width: 450px;
  @include max-md {
    max-width: 100%;
  }
  &__crumb {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: $green;
    cursor: pointer;

    &::after {
      content: ' / ';
      color: $gray;
    }

    &:last-child::after {
      content: '';
    }
  }
}
</style>
