<template>
  <div>
    <mt-loading-animation-linear v-if="!loaded" />

    <template v-else>
      <h2 class="cabinet__title">{{ respondent.full_name }} - планы оценок</h2>

      <div
          class="mt-data-table"
          style="border: none;"
          :class="{'mt-data-table_loading': respondentsLoading}"
      >
        <div
            v-for="(plan, planIndex) in computedPlans"
            :key="planIndex"
            class="mt-data-table__body"
        >
          <h2 class="mt-data-table__title">
            {{ plan.name }} - Кол-во баллов: <span>{{ plan.totalScore }}</span> ({{ plan.grade }})
          </h2>
          <div
              class="mt-data-table__wrapper"
              v-dragscroll
          >
            <table>
              <thead>
              <tr>
                <template
                    v-for="(test, index) in plan.tests"
                    :key="index"
                >
                  <th class="mt-data-table__border-left">
                    <div class="mt-data-table__cell">
                      <mtSvg
                          class="mt-data-table__header__icon mr-1"
                          color="#00788C"
                          :name="test.icon"
                          @click="showTaskDialog(test.uuid)"
                      />
                      {{ test.title }}
                      <button
                          v-if="test.hasOwnProperty('criterias') && test.criterias.length"
                          @click="toggleCriteriasDisplay(test)"
                      >
                        <div
                            class="mt-data-table__header__toggler"
                            :class="{'mt-data-table__header__toggler_active': test.uuid === activeTestColumn}"
                        />
                      </button>
                    </div>
                  </th>
                  <th
                      v-show="test.uuid === activeTestColumn"
                      v-for="(criteria, index) in test.criterias"
                      :key="index"
                  >
                    {{ criteria.name }}
                  </th>
                </template>
              </tr>
              </thead>
              <tbody>
              <tr>
                <template v-for="(test, index) in plan.tests">
                  <td
                      v-if="test.type === 'confirmation'"
                      class="mt-data-table__border-left"
                      :key="index"
                  >
                    <mt-checkbox
                        class="ml-auto mr-auto"
                        disabled="disabled"
                        v-model="test.isChecked"
                    />
                  </td>
                  <template v-else>
                    <td
                        :key="index"
                        class="mt-data-table__border-left"
                    >
                      <div class="mt-data-table__cell">
                        <template v-if="!test.sessionUuid">
                          <div class="mt-data-table__cell__more">
                            <template v-if="!test.currentScore && !test.totalScore">
                              -
                            </template>
                            <template v-else>
                              {{ !test.isSet ? '-' : test.currentScore }}
                              из
                              {{ test.totalScore }}
                            </template>
                          </div>
                        </template>
                        <template v-else>
                          <component
                              class="mt-data-table__cell__more"
                              :is="test.type === 'expert' && !assessmentPlan.isCompleted ? 'router-link' : 'div'"
                              :class="{'mt-data-table__cell__more_failed': test.isFailed, 'mt-data-table__cell__more_expert': test.type === 'expert' && !assessmentPlan.isCompleted}"
                              :to="{name: 'RespondentCabinetTaskSingle', params: {planUuid: this.$route.params.planUuid, taskUuid: test.uuid, respondentUuid: respondent.uuid}}"
                              @click="activeCustomAssessment = test"
                          >
                            <template v-if="!test.currentScore && !test.totalScore">
                              -
                            </template>
                            <template v-else>
                              {{ !test.isSet && !test.currentScore ? '-' : test.currentScore }}
                              из
                              {{ test.totalScore }}
                            </template>
                          </component>
                          <button
                              class="ml-2"
                              v-if="test.reports.length"
                              @click="openDownloadReportModal(respondent.uuid, test)"
                          >
                            <mtSvg
                                name="download"
                                color="#00788C"
                            />
                          </button>
                        </template>
                        <img
                            class="mt-data-table__cell__notification"
                            src="/img/icons/notification.svg"
                            v-if="test.hasNotifications"
                            alt=""
                        />
                      </div>
                    </td>
                    <td
                        v-show="test.uuid === activeTestColumn"
                        v-for="(criteria, criteriaIndex) in test.criterias"
                        :key="criteriaIndex"
                    >
                      <template v-if="criteria.currentScore === 0 && criteria.maxScore === 0">
                        -
                      </template>
                      <template v-else>
                        {{ !test.isSet  ? '-' : criteria.currentScore }} из {{ criteria.maxScore }}
                      </template>
                    </td>
                  </template>
                </template>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <mt-pagination
            class="mt-data-table__pagination"
            v-if="plans?.meta.total"
            :total="plans?.meta.total"
            v-model:page="plans.meta.current_page"
            v-model:perpage="plans.meta.per_page"
            @change="getPlans"
        />
      </div>
    </template>

    <teleport to="body">
      <mt-dialog
          class="custom-assessment"
          size="md"
          v-model="taskDialog"
      >
        <mt-loading-animation-linear v-if="taskLoading"/>
        <template v-else>
          <h3 class="custom-assessment__expander__title">{{ chosenTask.name }}</h3>
          <p
              class="custom-assessment__expander__text"
              v-show="chosenTask.description"
          >
            {{ chosenTask.description }}</p>
          <template v-if="chosenTask.materials.length">
            <h2 class="custom-assessment__expander__file-title">Файлы</h2>
            <div class="card mt-2">
              <file-download-card :links="chosenTask.materials"/>
            </div>
          </template>
        </template>
      </mt-dialog>

      <download-report-popover
          :visibility="isDownloadReportVisible"
          :reports="clipboardTask?.reports"
          @close="closeDownloadReportModal"
          @download="downloadReport"
      />
    </teleport>

    <router-view />
  </div>
</template>

<script>
import { respondentPlansAdapter } from "@/adapters/assessmentPlans/expRespPlans.adapter";
import { mapActions, mapState } from "vuex";
import MtSvg from "@/components/UI/mtSvg/index.vue";
import MtCheckbox from "@/components/UI/mtCheckbox/index.vue";
import { dragscroll } from "vue-dragscroll";
import { taskSingleAdapter } from "@/adapters/assessmentPlans/tasks/tasks.adapter";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";
import FileDownloadCard from "@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue";
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import { roundNumber } from "@/services/Mathe";
import MtPagination from "@/components/UI/mtPagination/mtPagination.vue";
import DownloadReportPopover from "@/components/workspace/testLibrary/results/DownloadReportPopover/index.vue";
import api from "@/network";
import externalApi from "@/network/external";

export default {
  name: "Cabinet",
  directives: {dragscroll},
  components: { DownloadReportPopover, MtPagination, MtDialog, FileDownloadCard, MtLoadingAnimationLinear, MtCheckbox, MtSvg },
  data: () => ({
    plans: {
      data: [],
      meta: {
        filters: {}
      }
    },
    respondent: {
      full_name: ''
    },
    activeCustomAssessment: {},
    activeTestColumn: null,
    respondentsLoading: false,
    chosenTask: {},

    taskDialog: false,
    taskLoading: true,

    isDownloadReportVisible: false,
    showInfo: true,
    hasScroll: false,
    clipboardUserUuid: null,
    clipboardTask: null,
    loaded: false
  }),
  computed: {
    ...mapState('assessmentPlans/plan', ['assessmentPlan']),
    ...mapState('assessmentPlans/plan/task', ['mark', 'marksChanged']),

    computedPlans() {
      return this.plans.data;
    },

    computedHeaderTestings() {
      if (this.plans.data.length === 0) return [];
      return this.plans.data[0].tests;
    },
  },
  methods: {
    ...mapActions(['fetchAdministrator']),
    ...mapActions('assessmentPlans/plan', ['fetchPlan']),
    ...mapActions('assessmentPlans/plan/task', ['fetchTask']),
    ...mapActions('breadcrumbs', ['setBreadcrumbs']),

    async getPlans() {
      let params = {
        page: this.plans.meta.current_page,
        per_page: this.plans.meta.per_page,
      };

      const {data} = await this.$workspaceApi.assessmentPlans.planListTable.getSingleRespondentPlans(this.$route.params.respondentUuid, {params});
      this.plans.data = respondentPlansAdapter(data.data);
      this.plans.meta = data.meta;
    },

    async getRespondentInfo() {
      const {data} = await this.fetchAdministrator({uuid: this.$route.params.respondentUuid});
      this.respondent = data.data;
    },

    async initBreadcrumbs() {
      let breadcrumbs = this.$route.meta.breadcrumbs;

      breadcrumbs.forEach(item => {
        if(item.link === 'RespondentCabinet') {
          item.value = this.respondent.full_name
        }
        if(item.link === 'AddEdit1') {
          item.value = this.assessmentPlan.name;
          item.params = {
            uuid: this.$route.params.planUuid
          };
        }

        return item;
      })

      await this.setBreadcrumbs([
          ...breadcrumbs,
        { value: this.respondent.full_name },
        { value: 'Результаты' },
      ]);
    },

    openDownloadReportModal(userUuid, task) {
      this.isDownloadReportVisible = true;
      this.clipboardUserUuid = userUuid;
      this.clipboardTask = task;
    },

    closeDownloadReportModal() {
      this.isDownloadReportVisible = false
    },

    async downloadReport({ format, variants, }) {
      const payload = [
        {
          format,
          variants,
          uuid: this.clipboardTask.sessionUuid,
          user_uuid: this.clipboardUserUuid,
        }
      ];
      const response = await api.post('export/results', payload);
      if (response.data?.url) {

        const reportLink = response.data.url;
        const type = format;
        const isGroup = variants.length > 1;

        if (isGroup) {
          window.open(reportLink, "_blank");
        } else {
          const response = await externalApi.get(`${ reportLink }`, {
            responseType: 'blob',
          });

          if (response.data.size) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;

            let fileName = `results__${ new Date().toLocaleDateString() }-${ new Date().toLocaleTimeString().replace(/:/g, '_') }.${ type }`;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            this.$toast.error('Отчёт не найден');
          }
        }
      }
    },

    async showTaskDialog(taskUuid) {
      this.taskDialog = true;
      this.taskLoading = true;

      const {data} = await this.fetchTask({
        uuidPlan: this.$route.params.planUuid,
        uuid: taskUuid
      });

      this.chosenTask = taskSingleAdapter(data.data);
      this.taskLoading = false;
    },

    toggleCriteriasDisplay(test) {
      if (this.activeTestColumn === test.uuid) {
        this.activeTestColumn = null;
        return;
      }
      this.activeTestColumn = test.uuid
    }
  },
  mounted() {
    Promise.all([
      this.fetchPlan({
        uuid: this.$route.params.planUuid,
      }),
      this.getPlans(),
      this.getRespondentInfo()
    ]).finally(() => {
      this.initBreadcrumbs();
      this.loaded = true;
    })
  },
  watch: {
    mark(val) {
      if (this.marksChanged) {
        this.getPlans()
      }
      let totalScore = val.scales ? val.scales.reduce((acc, scale) => {
        return acc + scale.value
      }, 0) : [];
      this.activeCustomAssessment.currentScore = roundNumber(totalScore);
    },
  }
}
</script>

<style lang="scss" scoped>
.cabinet__title {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.4px;
  color: #252733;
  margin-bottom: 35px;
}

.mt-data-table {
  background: #FFFFFF;
  border-radius: 8px;
  position: relative;
  max-width: 100%;
  transition: .3s opacity;

  &__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    span {
      color: #00788C;
    }
  }

  &__body {
    position: relative;
    margin-bottom: 50px;
  }

  &_loading {
    opacity: 0.7;
    pointer-events: none;
  }

  &__sort-icon {
    cursor: pointer;
    transition: transform .3s;

    &--active {
      transform: rotate(180deg);
    }
  }

  &__cell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    &_fullname {
      white-space: nowrap;
    }

    &__notification {
      width: 16px;
      margin-left: 8px;
    }
    &__more {
      color: $black;
      flex-shrink: 0;
      &_failed {
        color: #C24020;
      }
      &_expert {
        text-decoration: underline;
      }
    }
  }

  a.mt-data-table__cell_fullname {
    color: #000;
    text-decoration: underline;
    transition: color .3s;

    &:hover {
      color: #00788C;
    }
  }

  &__edit {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    svg {
      fill: $green;
    }
  }
  &__show-more {
    position: absolute;
    width: 200px;
    height: 100%;
    background: linear-gradient(to right, transparent, #ffffff);
    top: 0;
    right: 0;
    padding: 0;
    text-align: right;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    pointer-events: none;
    &_available {
      opacity: 1;
      visibility: visible;
    }
  }
  &__header {
    height: 57px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    &__toggler {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 8px solid $green;
      margin-left: 10px;
      &_active {
        transform: scale(-1);
      }
    }
  }

  &__wrapper {
    overflow-x: auto;
    border: 1px solid #DFE0EB;
    border-left: none;
    border-top: none;
    width: fit-content;
    max-width: 100%;
  }

  th, td {
    padding: 12px 10px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #252733;
    vertical-align: top;
  }

  th {
    white-space: nowrap;
    background: #F0F1F7;
    box-shadow: inset 0px -1px 0px #E6E7E9;
  }

  tbody tr > * {
    border-bottom: 1px solid #DFE0EB;
    min-width: 130px;
  }

  table {
    width: 100%;
  }

  &__header {
    &__icon {
      cursor: pointer;
      svg {
        width: 20px;
        height: 18px;
      }

      &_pre {
        margin-right: 16px;
      }
    }
  }

  &__border-right {
    border-right: 2px solid #E6E7E9;
  }

  &__border-left {
    border-left: 2px solid #E6E7E9;
  }

  &__pagination {
    justify-content: flex-end;
    min-height: 60px;
  }
}

.custom-assessment {
  &__title {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
    margin-bottom: 15px;
  }
  &__expander {
    margin-bottom: 20px;

    &__title {
      font-weight: 700;
      font-size: 19px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #252733;
      margin-bottom: 30px;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #252733;
      white-space: pre-wrap;
    }

    &__file-title {
      font-weight: 600;
      margin-top: 30px;
    }

    .expander {
      &__item {
        &__header {
          padding-top: 0;
          max-width: calc(100% - 50px);
          @include max-xs{
            flex-wrap: nowrap;
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>