<template>
  <div class="tests-library--settings-testing">
    <div class="tests-library--settings-testing__header">
      <div class="tests-library--settings-testing__title">Тестирование</div>
      <mt-button
          class="tests-library--settings-testing__apply"
          :loading="applyLoader"
          @click="apply"
      >
        Применить
      </mt-button>
    </div>
    <div class="tests-library--settings-testing__body">
      <div class="tests-library--settings-testing__option">
        <div class="tests-library--settings-testing__option--title">Доступ к тестированию</div>
        <mt-switch
            hide-details
            class="tests-library--settings-testing__option--action"
            theme="solid"
            v-model="testSettings.status"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions,} from "vuex";
import mtButton from "@/components/UI/mtButton/index.vue";
import mtSwitch from "@/components/UI/mtSwitch/index.vue";

export default {
  components: {
    mtButton,
    mtSwitch,
  },

  props: {},
  data: () => ({
    applyLoader: false,
  }),
  computed: {
    testSettings() {
      return this.$store.getters['test/settings'];
    },
  },

  watch: {},
  methods: {
    ...mapActions("testLibrary/tests", [
      'updateTests',
    ]),

    /* GETTERS */
    /* SETTERS */
    /* HANDLERS */
    async apply() {


      this.applyLoader = true;

      await this.updateTests({
        tests: [this.$route.params.uuid],
        status: this.testSettings.status,
      });

      this.applyLoader = false;
    },

    /* HELPERS */
  },

  created() {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.tests-library--settings-testing {
  background: #FFFFFF;
  border: 1px solid #E7E8F0;
  border-radius: 8px;
  padding: 22px 20px;

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
  }

  &__apply {
    width: 125px !important;
    height: 33px !important;
    border-radius: 8px !important;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    user-select: none;
  }

  &__option {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1.5px solid #E7E8F0;
    border-radius: 8px;
    padding: 12px 15px;
    margin-top: 20px;


    &--title {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }

    &--action {
    }
  }
}
</style>