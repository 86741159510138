<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import AppChip from '@/components/UI/AppChips/AppChip.vue'
import AppFormBlock from '@/components/UI/AppFormBlock/AppFormBlock.vue'
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue'
import MtLoadingAnimationLinear from '@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue'
import CloseIcon from '@/components/UI/mtSvg/svg/CloseIcon.vue'
import { deepClone } from '@/services/utils.js'

//rewrite props to ts type
type Props = {
  half?: boolean
  modelValue?: any
  disabled?: boolean
  fromTop?: boolean
  name?: string
  label?: string
  items: any[]
  itemText?: string
  itemValue?: string
  itemReadonly?: Function
  itemDisabled?: Function
  height?: number
  clearable?: boolean
  color?: string
  returnObject?: boolean
  multiple?: boolean
  errors?: any[] | boolean
  loading?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  half: false,
  disabled: false,
  fromTop: false,
  name: '',
  label: '',
  items: () => [],
  itemText: 'label',
  itemValue: 'value',
  clearable: false,
  returnObject: false,
  multiple: false,
  errors: () => [],
  loading: false,
})

const slots = useSlots()
const emit = defineEmits(['update:modelValue', 'change', 'focus'])
const root = ref(null)
const showOptions = ref(false)

const headerText = computed(() => {
  if (selectedItem.value) return selectedItem.value[props.itemText]

  return props.title ?? props.modelValue
})

const selectedItem = computed(() => {
  if (props.items.length === 0) return null

  if (props.returnObject) return props.modelValue

  return props.items.find((item) => {
    if (Array.isArray(props.modelValue)) {
      return props.modelValue.includes(item[props.itemValue])
    } else {
      return item[props.itemValue] === props.modelValue
    }
  })
})

const computedItems = computed(() => {
  let items = deepClone(props.items)
  if (typeof items[0] !== 'object') {
    items = props.items.map((item) => ({
      label: String(item),
      value: item,
    }))
  }

  return items.map((item) => {
    item.isReadonly = props.itemReadonly ? props.itemReadonly(item) : false
    item.isDisabled = props.itemDisabled ? props.itemDisabled(item) : false
    return item
  })
})

function checkIfItemIsSelected(item) {
  if (!props.modelValue) return
  if (!props.multiple) {
    if (!selectedItem.value) return false
    const valueForCheck = props.returnObject
      ? selectedItem.value[props.itemValue]
      : selectedItem.value[props.itemValue]
    return item[props.itemValue] === valueForCheck
  }

  if (props.returnObject) {
    return props.modelValue.some((selectedItem) => {
      return selectedItem[props.itemValue] === item[props.itemValue]
    })
  }
}

function toggle(e) {
  if (slots['header'] && e.target.nodeName === 'INPUT') return
  showOptions.value = !showOptions.value
}

function open() {
  showOptions.value = true
}

function selectItem(item) {
  if (item.isReadonly) return

  const valueToPush = props.returnObject ? item : item[props.itemValue]
  emit('update:modelValue', valueToPush)
  emit('change', valueToPush)
  showOptions.value = false
}

function toggleItem(item) {
  if (checkIfItemIsSelected(item)) {
    removeSelectedItem(item)
  } else {
    addItemToSelected(item)
  }
}

function addItemToSelected(item) {
  const valueToPush = props.returnObject ? item : item[props.itemValue]
  props.modelValue.push(valueToPush)
}

function removeSelectedItem(item) {
  if (Array.isArray(props.modelValue)) {
    const index = props.modelValue.findIndex(
      (selectedItem) => selectedItem === item[props.itemValue],
    )
    props.modelValue.splice(index, 1)
  } else {
    emit('update:modelValue', null)
    emit('change')
  }
}

function clear() {
  emit('update:modelValue', null)
  emit('change')
}

function onClickOutside(e) {
  if (!root.value.contains(e.target)) {
    showOptions.value = false
  }
}

window.onkeydown = (e) => {
  if (e.key === 'Escape') {
    showOptions.value = false
  }
}

function selectItemFromOutside() {
  if (props.modelValue) {
    const item = props.items.find(
      (item) => item[props.itemValue] === props.modelValue,
    )
    if (item) selectItem(item)
  }
}

function activateOptions() {
  showOptions.value = true
}

onMounted(() => {
  window.addEventListener('click', onClickOutside)
})

onUnmounted(() => {
  window.removeEventListener('click', onClickOutside)
})

const unwatch = watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) return unwatch()
    selectItemFromOutside()
    unwatch()
  },
)

watch(showOptions, () => {
  if (showOptions.value) emit('focus')
})

defineExpose({
  headerText,
  activateOptions,
})
</script>

<template>
  <app-form-block
    :half="half"
    :active="!!headerText"
    :errors="Array.isArray(errors) ? errors : []"
  >
    <template #label>
      {{ label }}
      <slot name="label" />
    </template>
    <div
      ref="root"
      :class="[
        'app-select',
        {
          'app-select--active': showOptions,
          'app-select--from-top': props.fromTop,
          'app-select--error': props.errors.length,
          'app-select--loading': props.loading,
          'app-select--disabled': props.disabled,
        },
        color && `app-select--${color}`,
      ]"
    >
      <button
        v-if="clearable && selectedItem"
        class="app-select__clear"
        @click.prevent="clear()"
      >
        <close-icon class="app-select__clear__icon" />
      </button>

      <button
        :style="{
          height: height ? height + 'px' : '',
        }"
        :tabindex="!computedItems.length ? '-1' : ''"
        class="app-select__header"
        @click="toggle($event)"
      >
        <span class="app-select__wrap">
          <slot name="header" v-if="$slots['header']"></slot>
          <span class="app-select__wrap__text" v-else>
            {{ headerText }}
          </span>
        </span>

        <arrow-down-icon class="app-select__arrow" />
      </button>

      <div class="app-select__options">
        <div
          v-if="computedItems.length === 0"
          class="app-select__option app-select__option--no-selectable"
        >
          <i>Пусто</i>
        </div>
        <button
          v-for="(item, index) in computedItems"
          :key="index"
          :class="[
            'app-select__option',
            {
              'app-select__option--selected': checkIfItemIsSelected(item),
              'app-select__option--readonly': item.isReadonly,
              'app-select__option--disabled': item.isDisabled,
            },
          ]"
          @click="selectItem(item)"
        >
          <slot v-bind="item" name="item">
            {{ item[itemText] }}
          </slot>
        </button>
      </div>
    </div>
    <mt-loading-animation-linear v-if="props.loading" />
  </app-form-block>
</template>

<style lang="scss" src="./AppSelect.scss" />
