<template>
  <app-data-table
    v-model:pagination="computedPagination"
    v-model:selectedItems="computedSelectedItems"
    :headers="headers"
    :header-title-key="headerTitleKey"
    :header-value-key="headerValueKey"
    :items="items"
    :checkable="checkable"
    :is-loading="isFetching"
    :is-loaded="isLoaded"
    :with-pagination="withPagination"
    :is-pagination-request-pending="isPaginationRequestPending"
  >
    <template #title v-if="Object.keys(this.$slots).includes('title')">
      <slot name="title" />
    </template>
    <template
      #header-right
      v-if="Object.keys(this.$slots).includes('header-right')"
    >
      <slot name="header-right" />
    </template>
    <template #header-actions>
      <slot name="header-actions" />

      <mt-filters
        v-model:cols="computedHeaders"
        v-model:filters="filters"
        v-model:sort="sort"
        v-model:search="search"
        :search-visibility="searchable"
        :cols-visibility="colsable"
        :filter-visibility="filterable"
        :filters-settings="filtersSettings"
        class="assessment-plans__mt-filters"
      />
    </template>

    <template v-if="Object.keys($slots).includes('tbody')" #tbody>
      <slot name="tbody" />
    </template>

    <template v-for="slot in computedSlots" v-slot:[`${slot}`]="item">
      <slot :name="slot" v-bind="item" />
    </template>

    <template
      v-if="Object.keys($slots).includes('footer-actions') || withPagination"
      #footer-actions
    >
      <slot name="footer-actions" />
    </template>
  </app-data-table>
</template>

<script>
import { defineComponent, provide, ref } from 'vue'
import mtIcon from '@/components/UI/mtIcon/mtIcon.vue'
import AppDataTable from '@/components/UI/AppDataTable/AppDataTable.vue'
import mtFilters from '@/components/UI/mtFilters/mtFilters.vue'
import { deepClone } from '@/services/utils.js'

export default defineComponent({
  name: 'AppServerDataTable',
  components: { mtFilters, AppDataTable, mtIcon },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    headerTitleKey: {
      type: String,
      default: 'title',
    },
    headerValueKey: {
      type: String,
      default: 'stub',
    },
    params: {
      type: Object,
      default: {},
    },
    selectedItems: Array,
    checkable: Boolean,
    colsable: Boolean,
    sortable: Boolean,
    filterable: Boolean,
    searchable: Boolean,
    withPagination: Boolean,
    saveOnUrl: Boolean,
    itemsProvider: Function,
  },
  data() {
    return {
      items: [],
      dataTableStaticSlots: [
        'title',
        'header-actions',
        'footer-actions',
        'tbody',
      ],
      filters: [],
      search: {},
      filtersSettings: [],
      filterByResult: null,
      page: 1,
      total: 0,
      perPage: 15,

      isPaginationRequestPending: false,
      isFetching: false,
      isLoaded: false,
    }
  },
  setup(props) {
    const sort = ref(props.sortable ? {} : null)

    provide('sort', sort)

    return {
      sort,
    }
  },

  computed: {
    computedSlots() {
      return Object.keys(this.$slots).filter(
        (i) => !this.dataTableStaticSlots.includes(i),
      )
    },

    computedHeaders: {
      get() {
        return this.headers
      },
      set(newValue) {
        this.$emit('update:headers', newValue)
      },
    },

    computedPagination: {
      get() {
        return {
          page: this.page,
          perPage: this.perPage,
          total: this.total,
        }
      },
      set(newValue) {
        if (!newValue) return

        this.isPaginationRequestPending = true

        this.page = newValue.page
        this.perPage = newValue.perPage

        if (this.saveOnUrl) {
          this.$router.push({
            query: {
              page: newValue.page,
              perPage: newValue.perPage,
            },
          })
        }

        this.getItems()
      },
    },

    computedParams: {
      get() {
        return this.params
      },
      set(newValue) {
        this.$emit('update:params', newValue)
      },
    },

    computedSelectedItems: {
      get() {
        return this.selectedItems || []
      },
      set(newValue) {
        this.$emit('update:selectedItems', newValue)
      },
    },
  },
  methods: {
    async getItems() {
      if (!this.itemsProvider) return

      let clonedParams = deepClone(this.computedParams)
      clonedParams.page = this.page
      clonedParams.perpage = this.perPage
      clonedParams.settings = deepClone(this.sort)

      if (this.filters.length) {
        clonedParams = {
          ...clonedParams,
          settings: {
            ...clonedParams.settings,
            filters: this.filters,
          },
        }
      }

      if (this.filterByResult) {
        clonedParams = {
          ...clonedParams,
          result: this.filterByResult,
        }
      }

      if (!!this.search.value) {
        clonedParams.settings['value'] = this.search.value

        if (!!this.search.search) {
          clonedParams.settings['search'] = this.search.search
        }
      }

      this.computedParams = clonedParams
      if (!this.isPaginationRequestPending) this.isFetching = true

      return await this.itemsProvider()
        .then(({ data }) => {
          this.items = data?.data
          this.total = data?.meta?.total
          this.filtersSettings = data?.meta?.filters
        })
        .finally(() => {
          this.isFetching = false
          this.isPaginationRequestPending = false
        })
    },
  },
  async created() {
    const activeCols = this.$route.query?.cols?.split(',')
    if (activeCols) {
      this.computedHeaders.forEach((item) => (item.visible = false))
      activeCols.map((item) => {
        const foundCol = this.computedHeaders.find((col) => col.stub === item)
        if (foundCol) foundCol.visible = true
      })
    }

    if (this.$route.query && this.saveOnUrl) {
      const query = this.$route.query

      Object.keys(query).forEach((key) => {
        switch (key) {
          case 'page':
            return (this.page = +query.page)
          case 'perPage':
            return (this.perPage = +query.perPage)
          case 'sort':
            return (this.sort = JSON.parse(this.$route.query.sort))
          case 'search':
            return (this.search.value = this.$route.query.search)
          case 'filters':
            const parsedFilters = JSON.parse(this.$route.query.filters)
            this.filters = parsedFilters

            const searchFilter = parsedFilters.find(
              (item) => item.field === 'name',
            )
            if (searchFilter) this.search.value = searchFilter.value
            break
        }
      })
    }

    if (this.$route.params) {
      const params = this.$route.params

      Object.keys(params).forEach((key) => {
        switch (key) {
          case 'filters':
            this.filters = [
              ...this.filters,
              ...JSON.parse(this.$route.params.filters),
            ]
            break
          case 'result':
            return (this.filterByResult = this.$route.params.result)
        }
      })
    }

    this.getItems().finally(() => {
      this.isLoaded = true
    })
  },
  watch: {
    headers: {
      handler() {
        const areAllColsVisible = this.computedHeaders
          .filter((i) => i.showable)
          .every((i) => i.visible)

        if (areAllColsVisible) {
          const query = deepClone(this.$route.query)
          delete query.cols

          if (this.saveOnUrl) return this.$router.push({ query })
        }

        const activeCols = this.computedHeaders
          .filter((col) => col.visible)
          .map((item) => item.stub)
          .join(',')

        if (this.saveOnUrl) return
        this.$router.push({
          query: {
            ...this.$route.query,
            cols: activeCols,
          },
        })
      },
      deep: true,
    },
    filters: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) === this.$route.query.filters) return

        const modifiedFilters = newValue.filter((item) => item.value)

        const hasBeenSearch = oldValue.find((i) => i.field === 'name')
        const noSearchInNew = !newValue.find((i) => i.field === 'name')

        const query = { ...this.$route.query }
        if (modifiedFilters.length) {
          query.filters = JSON.stringify(modifiedFilters)
        } else {
          delete query.filters
        }

        if (this.saveOnUrl) this.$router.push({ query: { ...query } })

        if (hasBeenSearch && noSearchInNew) {
          this.search.value = ''
        }

        this.getItems()
      },
      deep: true,
    },
    sort: {
      handler(newValue) {
        if (!newValue) {
          this.sort = {}
          const modifiedQuery = { ...this.$route.query }
          delete modifiedQuery.sort

          this.$router.push({
            query: modifiedQuery,
          })
        } else {
          if (JSON.stringify(newValue) === this.$route.query.sort) return

          if (this.saveOnUrl && Object.keys(newValue).length) {
            this.$router.push({
              query: {
                ...this.$route.query,
                sort: JSON.stringify(newValue),
              },
            })
          }
        }

        this.getItems()
      },
      deep: true,
    },
    search: {
      handler(newValue) {
        if (!newValue.value && this.$route.query.search) {
          const query = { ...this.$route.query }
          delete query.search

          this.$router.replace({ query })
        } else if (this.saveOnUrl) {
          this.$router.push({
            query: {
              ...this.$route.query,
              search: newValue.value,
            },
          })
        }

        this.getItems()
      },
      deep: true,
    },
  },
})
</script>

<style lang="scss" src="./AppServerDataTable.scss" />
