
export function inputTestScalesAdapter(payload = null) {


  if (!payload.data?.data) return [];

  const SCALES = payload.data.data;

  return [
    // ...scales,

    ...SCALES.map((scale) => ({
      ...scale,
      name: scale.title,
      selected: false, 
    })),
  ];
}