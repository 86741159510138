<script setup lang="ts">
import PaperIcon from "@/components/icons/PaperIcon.vue";
import DoubleCheckIcon from "@/components/icons/DoubleCheckIcon.vue";
import AccountSearchOutlineIcon from "@/components/icons/AccountSearchOutlineIcon.vue";

interface Props {
  type: string;
}

const props = defineProps<Props>();


const computedIcon = computed(() => {
  if(props.type === 'test') {
    return PaperIcon;
  } else if(props.type === 'confirmation') {
    return DoubleCheckIcon;
  } else if(props.type === 'expert') {
    return AccountSearchOutlineIcon;
  }
});



</script>

<template>
  <div :class="[
      'task-icon-wrapper',
      `task-icon-wrapper_${props.type}`
  ]">

    <component
        :is="computedIcon"
        class="task-icon"
    />
  </div>

</template>

<style lang="scss">
.task-icon {
  width: 18px;
  height: 18px;
  &-wrapper {
    color: #fff;
    border-radius: 4px;
    @include flexCenter;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    &_test {
      background-color: $brown;
    }
    &_expert {
      background-color: #6D6F71;
    }
    &_confirmation {
      background-color: $green;
    }
  }
}
</style>