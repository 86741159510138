<template>
  <div class="mt-res-report-options">
    <div
        class="mt-res-report-options__option"
        v-for="option in options"
        :key="option.variant"
        @click.self="option.selected = !option.selected"
    >
      <div
          class="mt-res-report-options__option-title"
          @click.self="option.selected = !option.selected"
      >
        {{ option.title }}
      </div>
      <mt-checkbox
          class="mt-res-report-options__option-action"
          v-model="option.selected"
      />
    </div>
  </div>
</template>

<script>
import mtCheckbox from '@/components/UI/mtCheckbox/index.vue';

export default {
  components: {
    mtCheckbox,
  },

  props: {
    options: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({}),
  computed: {},

  watch: {},
  methods: {
    /* GETTERS */
    /* SETTERS */
    /* HANDLERS */
    /* HELPERS */
  },

  created() {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.mt-res-report-options {
  margin-top: 20px;

  &__option {
    font-family: 'Mulish';
    font-style: normal;
    border: 1.5px solid #DFE0EB;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }

    &-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #000000;
      user-select: none;
    }

    &-action {
    }
  }
}
</style>