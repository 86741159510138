<template>
  <label class="mt-radio-button">
    <input
        class="mt-radio-button--radio"
        ref="rbinput"
        type="radio"
        :id="id"
        :name="name"
        :checked="value"
        :disabled="disabled"
        @change="change({ value: $event.target.checked, id, })"
    />
    <label
        class="radio-label mt-radio-button--title"
        :for="id"
    >
      {{ title }}
    </label>
  </label>
</template>

<script>
export default {
  name: 'mtRadioButton',
  components: {},
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    change({id,}) {
      if (this.disabled) return;

      this.$emit('update:modelValue', id);
      this.$emit('update');
    },
  },
}
</script>

<style
    lang="scss"
    src="./mtRadioButton.scss"
/>