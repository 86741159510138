<template>
  <planDefault>
    <template #headerStatistics>
      <statistics-amount
          class="assessment-plan__expander__statistics-circle"
          :amount="assessmentPlan.participantsAmount"
      />
    </template>
    <template v-slot:default="assessmentPlan">
      <mt-loading-animation-linear
          class="mb-1"
          :style="{'opacity': respondentsLoading ? '1' : '0'}"
      />
      <Alert
          v-if="computedDateAlert"
          class="mb-4"
          type="warning"
      >
        {{ computedDateAlert }}
      </Alert>
      <Alert
        class="mb-4"
        type="warning"
        v-if="assessmentPlan.isStopped"
      >
        План оценок остановлен
      </Alert>
      <Alert
          class="mb-4"
          v-if="assessmentPlan.isCompleted"
          type="warning"
      >
        План оценок завершен
      </Alert>

      <expert-review-table />

      <mt-button
          class="assessment-plan__submit-btn"
          @click="openConfirmationToResume"
          v-if="isExpert"
      >
        {{ assessmentPlan.isCompleted ? 'Возобновить' : 'Завершить' }}
      </mt-button>


      <teleport to="body">
        <confirmation-dialog
            v-model="isConfirmationDialogOpen"
            :title="computedTextForConfirmationDialog.title"
            :text="computedTextForConfirmationDialog.text"
            :handler="runCompletePlanAction"
        />
      </teleport>
    </template>
  </planDefault>
  <router-view :key="routerKey"/>
</template>

<script>
import planDefault from '@/components/workspace/assessmentPlans/plans/plan/default/index.vue';
import statisticsAmount from '@/components/workspace/assessmentPlans/plans/statisticsAmount/index.vue';
import ConfirmationDialog from '@/components/composite/confirmationDialog/confirmationDialog.vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import MtLoadingAnimationLinear from '@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue';
import Alert from '@/components/UI/AppAlert/AppAlert.vue';
import ExpertReviewTable from "@/components/workspace/ExpertReviewTable.vue";
import mtButton from "@/components/UI/mtButton/index.vue";

export default {
  name: 'planExpert',
  components: {
    ExpertReviewTable,
    Alert,
    MtLoadingAnimationLinear,
    ConfirmationDialog,
    mtButton,
    statisticsAmount,
    planDefault
  },
  data: () => ({
    taskDialog: false,
    taskLoading: true,

    chosenTask: {},
    routerKey: 1,

    reports: [
      {
        variant: 0,
        selected: false,
        title: 'для респондента'
      }
    ],

    isConfirmationDialogOpen: false,
    isExpertAssessmentDialogOpen: false,

    clipboardTask: null,
    activeTestColumn: null,

    respondentsLoading: false,
  }),
  computed: {
    ...mapState('assessmentPlans/plan', ['assessmentPlan']),
    ...mapGetters(['isExpert']),

    computedTextForConfirmationDialog () {
      const title = this.assessmentPlan.isCompleted ? 'Возобновить' : 'Завершить'
      const text = this.assessmentPlan.isCompleted ? 'Возобновить работу с планом оценки?' : 'Завершить работу с планом оценки?'

      return {title, text}
    },

    computedExpiredDate () {
      const today = new Date()
      const dateFrom = this.assessmentPlan.dateFromUnformatted ? new Date(this.assessmentPlan.dateFromUnformatted) : null
      const dateTo = this.assessmentPlan.dateToUnformatted ? new Date(this.assessmentPlan.dateToUnformatted) : null

      if(dateFrom?.valueOf() > today.valueOf()) {
        return false
      }

      if(dateTo?.valueOf() < today.valueOf()) {
        return true
      }

      return null
    },

    computedDateAlert() {
      if(this.computedExpiredDate === null) return null;

      return !this.computedExpiredDate ? 'План оценки станет доступен позднее.' : 'Истёк срок работы с данным планом оценки'
    }
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['completePlan', 'resumePlan']),
    runCompletePlanAction() {
      if(!this.assessmentPlan.isCompleted) {
        this.completePlan(this.assessmentPlan.uuid).then(() => {
          this.isConfirmationDialogOpen = false;
        });
      } else {
        this.resumePlan(this.assessmentPlan.uuid).then(() => {
          this.isConfirmationDialogOpen = false;
        });
      }
    },

    openConfirmationToResume() {
      this.isConfirmationDialogOpen = true
    }
  }
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>