<script setup>
import { computed } from "vue";
import { generateRandomID } from "@/services/utils";

const props = defineProps({
  modelValue: {
    type: [Boolean, String, Number, Array],
    default: false
  },
  value: {
    type: [Number, String, Boolean],
    default: null
  },
  label: {
    type: String,
  },
  name: {
    type: String,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue'])
const randomID = generateRandomID();

const computedIsActive = computed(() => {
  return props.modelValue === props.value
})

function change () {
  emit('update:modelValue', props.value);
}


</script>

<template>
  <div
    :class="['app-radio', {
      'app-radio--disabled': disabled
    }]"
  >
    <input
      :id="`${randomID}`"
      :checked="computedIsActive"
      :disabled="disabled"
      :name="name"
      class="app-radio__input"
      type="radio"
      @change="change()"
    >
    <label
      :for="`${randomID}`"
      class="app-radio__label"
    >
      <span class="app-radio__label__text">
        <slot name="label"/>

        {{ label }}
      </span>
    </label>
  </div>
</template>

<style lang="scss" src="./AppRadio.scss"/>