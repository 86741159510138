<template>
  <div>
    <component
        :is="getPlansPage(role)"
        :rows="plansRows"
    />
    <router-link
        class="assessment-plans__archive-link"
        :to="{
          name: 'AssessmentPlansArchive'
        }"
        v-if="role === 'expert'"
    >
      Перейти в архив
    </router-link>
  </div>
</template>

<script>
import plansDefault from "@/components/workspace/assessmentPlans/plans/plansDefault.vue";
import plansExpert from "@/components/workspace/assessmentPlans/plans/plansExpert.vue";
import plansRespondent from "@/components/workspace/assessmentPlans/plans/plansRespondent.vue";
import { mapActions, mapGetters } from 'vuex';
import mtLoadingAnimationLinear from '@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue';

export default {
  components: {
    mtLoadingAnimationLinear,
    plansDefault,
    plansExpert,
    plansRespondent,
  },

  data: () => {
    return {
      loaded: false
    }
  },

  computed: {
    ...mapGetters('assessmentPlans/plan', ['plansRows']),
    role() {
      return this.$store.getters['authUser']?.role?.slug || '';
    },
  },

  methods: {
    ...mapActions('assessmentPlans/plan', ['fetchActivePlans', 'fetchCompletedPlans']),

    /* GETTERS */
    getPlansPage(role) {
      switch (role.toLowerCase()) {
        case 'admin':
        case 'metodolog':
        case 'operator':
          return 'plansDefault';

        case 'expert':
        case 'inspector':
          return 'plansExpert';

        case 'respondent':
          return 'plansRespondent';

        default:
          return null;
      }
    }
  },
  async mounted() {
    if(this.role === 'admin') {
      this.loaded = true;
      return;
    }

    Promise.all([
      this.fetchActivePlans({perpage: 12}),
      this.fetchCompletedPlans({perpage: 12})
    ])
        .finally(() => {
          this.loaded = true;
        })
  },
}
</script>

<style lang="scss">
.assessment-plans {
  &__header {
    display: none;
    margin: -10px 0 30px;
  }

  &__nav {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-bottom: 10px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.4px;
    color: #252733;
    margin-bottom: 5px;
    padding: 0;
  }

  &__mt-filters {
    margin-left: auto;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #00788C;
  }

  &__archive-link {
    margin-top: 16px;
    display: block;
    width: fit-content;
    color: $green;

    @include max-sm {
      margin: 30px auto 0;
    }
  }

  &__mt-pagination {
    padding: 0;
    align-self: flex-start;
  }

  @include max-md {
    &__actions {
      flex-direction: column;
      align-items: unset;

    }
  }

  @include max-xs {
    &__header {
      display: block;

      &-cell {
        padding: 12px 15px;
      }
    }

    &__title {
      font-size: 20px;
      margin-bottom: 15px;
    }

    &__nav {
      flex-wrap: wrap;
      height: unset;
      align-items: flex-start;
    }

    &__add {
      padding: 6px 10px;
      margin-bottom: 15px;

      svg {
        min-width: 18px;
        max-width: 18px;
        height: 18px;
      }

      span {
        font-size: 12px;
      }
    }


    &__item {
      > div {
        padding: 10px 15px;
      }
    }
  }
}
</style>