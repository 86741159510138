export default function (instance) {
    return {
        createAppeal() {
            return instance.post(`support`);
        },

        sendAppeal(support_uuid, body) {
            return instance.put(`support/${support_uuid}`, body);
        },

        deleteAppeal(support_uuid) {
            return instance.delete(`support/${support_uuid}`);
        },

        uploadFiles(support_uuid, file) {
            let formData = new FormData();

            formData.append("file", file);
            return instance.post(`support/${support_uuid}/file`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            });
        },

        deleteFile(file_uuid) {
            return instance.delete(`files/${file_uuid}`);
        },
    }
}
