<template>
  <div class="tests-library--settings-export-excel">
    <div class="tests-library--settings-export-excel__header">
      <div class="tests-library--settings-export-excel__title">Настройки экспорта в Excel</div>
      <mt-button
          class="tests-library--settings-export-excel__apply"
          :loading="applyLoader"
          @click="apply"
      >
        Применить
      </mt-button>
    </div>
    <div class="tests-library--settings-export-excel__body">
      <div class="tests-library--settings-export-excel__row">
        <div class="tests-library--settings-export-excel__row--col-1">
          <mt-checkbox
              class="tests-library--settings-export-excel__row-checkbox"
              v-model="testSettings.points"
          />
          <div
              class="tests-library--settings-export-excel__row-title"
              @click="setPoints(!testSettings.points)"
          >
            Баллы
          </div>
        </div>
        <div class="tests-library--settings-export-excel__row--col-2">
          <div class="tests-library--settings-export-excel__row--col-2-option">
            <div class="tests-library--settings-export-excel__units">
              <div class="tests-library--settings-export-excel__row--col-2-option--title">Единицы измерения</div>
              <app-radio
                  name="units"
                  label="По умолчанию"
                  :value="testSettings.defaultPoints"
                  :disabled="!testSettings.points"
                  style="margin: 15px 0"
                  @update:modelValue="updateDefaultPoints(!testSettings.defaultPoints)"
              />
              <app-radio
                  name="units"
                  label="Указать вручную"
                  :value="!testSettings.defaultPoints"
                  disabled
                  @update:modelValue="updateDefaultPoints(!testSettings.defaultPoints)"
              />
            </div>
          </div>
          <div
              class="tests-library--settings-export-excel__row--col-2-option"
              style="margin-top: 20px"
          >
            <div class="tests-library--settings-export-excel__units">
              <div class="tests-library--settings-export-excel__row--col-2-option--title">Список шкал</div>

              <app-radio
                  v-model="testSettings.defaultScales"
                  name="scales1"
                  label="По умолчанию"
                  :value="true"
                  :disabled="!testSettings.points"
                  style="margin: 15px 0"
                  @update:modelValue="updateDefaultScales(true)"
              />
              <app-radio
                  v-model="testSettings.defaultScales"
                  name="scales1"
                  label="Указать вручную"
                  :value="false"
                  :disabled="!testSettings.points"
                  @update:modelValue="updateDefaultScales(false)"
              />
            </div>
          </div>
          <div
              class="tests-library--settings-export-excel__row--col-2-option"
              style="margin-top: 20px"
          >
            <mtSelectMultiple
                class="tests-library--settings-export-excel__scales-list"
                :items="computedScales"
                :disabled="testSettings.defaultScales"
                @update="updateSelectedScaleOptions({ options: $event })"
            />
          </div>
        </div>
      </div>
      <div class="tests-library--settings-export-excel__row">
        <div class="tests-library--settings-export-excel__row--col-1">
          <mt-checkbox
              class="tests-library--settings-export-excel__row-checkbox"
              v-model="testSettings.tasks"
          />
          <div
              class="tests-library--settings-export-excel__row-title"
              @click="setTasks(!testSettings.tasks)"
          >
            Задания
          </div>
        </div>
        <div class="tests-library--settings-export-excel__row--col-2">
          <div class="tests-library--settings-export-excel__row--col-2-option">
            <mt-checkbox
                class="tests-library--settings-export-excel__row-checkbox"
                v-model="testSettings.responseTime"
                :class="[{ 'tests-library--settings-export-excel__row-checkbox_disabled': !testSettings.tasks }]"
                :disabled="!testSettings.tasks"
            />
            <div
                class="tests-library--settings-export-excel__row-title"
                :class="[{ 'tests-library--settings-export-excel__row-title_disabled': !testSettings.tasks }]"
                @click="updateResponseTime({ value: !testSettings.responseTime })"
            >
              Время ответа
            </div>
          </div>
          <div class="tests-library--settings-export-excel__row--col-2-option">
            <mt-checkbox
                class="tests-library--settings-export-excel__row-checkbox"
                v-model="testSettings.answersToTasks"
                :class="[{ 'tests-library--settings-export-excel__row-checkbox_disabled': !testSettings.tasks }]"
                :disabled="!testSettings.tasks"
            />
            <div
                class="tests-library--settings-export-excel__row-title"
                :class="[{ 'tests-library--settings-export-excel__row-title_disabled': !testSettings.tasks }]"
                @click="updateAnswersToTasks({ value: !testSettings.answersToTasks })"
            >
              Ответы на задания
            </div>
          </div>
          <div class="tests-library--settings-export-excel__row--col-2-option">
            <mt-checkbox
                class="tests-library--settings-export-excel__row-checkbox"
                v-model="testSettings.rawPointForTasks"
                :class="[{ 'tests-library--settings-export-excel__row-checkbox_disabled': !testSettings.tasks }]"
                :disabled="!testSettings.tasks"
            />
            <div
                class="tests-library--settings-export-excel__row-title"
                :class="[{ 'tests-library--settings-export-excel__row-title_disabled': !testSettings.tasks }]"
                @click="updateRawPointForTasks({ value: !testSettings.rawPointForTasks })"
            >
              Сырой балл по заданиям
            </div>
          </div>
          <div class="tests-library--settings-export-excel__row--col-2-option">
            <mt-checkbox
                class="tests-library--settings-export-excel__row-checkbox"
                v-model="testSettings.matchPercentage"
                :class="[{ 'tests-library--settings-export-excel__row-checkbox_disabled': !testSettings.tasks }]"
                :disabled="!testSettings.tasks"
            />
            <div
                class="tests-library--settings-export-excel__row-title"
                :class="[{ 'tests-library--settings-export-excel__row-title_disabled': !testSettings.tasks }]"
                @click="updateMatchPercentage({ value: !testSettings.matchPercentage })"
            >
              Процент совпадений
            </div>
          </div>
        </div>
      </div>
      <div class="tests-library--settings-export-excel__row">
        <div class="tests-library--settings-export-excel__row--col-1">
          <mt-checkbox
              class="tests-library--settings-export-excel__row-checkbox"
              v-model="testSettings.respondentData"
          />
          <div
              class="tests-library--settings-export-excel__row-title"
              @click="setRespondentData(!testSettings.respondentData)"
          >
            Данные о респонденте
          </div>
        </div>
        <div class="tests-library--settings-export-excel__row--col-2">
          <div class="tests-library--settings-export-excel__row--col-2-option">
            <mt-checkbox
                class="tests-library--settings-export-excel__row-checkbox"
                v-model="testSettings.respondentViewSettings"
                :class="[{ 'tests-library--settings-export-excel__row-checkbox_disabled': !testSettings.respondentData }]"
                :disabled="!testSettings.respondentData"
            />
            <div
                class="tests-library--settings-export-excel__row-title"
                :class="[{ 'tests-library--settings-export-excel__row-title_disabled': !testSettings.respondentData }]"
                @click="updateRespondentViewSettings({ value: !testSettings.respondentViewSettings })"
            >
              Учитывать настройки просмотра
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tests-library--settings-export-excel__footer">
      <div class="tests-library--settings-export-excel__footer--title">Структура Excel-файла:</div>
      <mtSelect
          class="tests-library--settings-export-excel__footer--select"
          v-model="excelStructureOption"
          :items="excelStructureOptions"
      />
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {outputSettingsAdapter} from "@/adapters/testLibrary/tests/testSettings.adapter";
import {inputTestScalesAdapter} from "@/adapters/dictionary/testScales.adapter";
import mtButton from "@/components/UI/mtButton/index.vue";
import mtCheckbox from "@/components/UI/mtCheckbox/index.vue";
import mtRadioButton from "@/components/UI/mtRadioButton/mtRadioButton.vue";
import mtSelect from "@/components/UI/mtSelectSolid/mtSelectSolid.vue";
import mtSelectMultiple from "@/components/composite/mtSelectMultiple.vue";
import AppRadio from "@/components/UI/AppRadio/AppRadio.vue";

export default {
  components: {
    AppRadio,
    mtButton,
    mtCheckbox,
    mtRadioButton,
    mtSelect,
    mtSelectMultiple,
  },

  props: {},
  data: () => ({
    excelStructureOption: "Выбрать",
    excelStructureOptions: [
      {
        name: "Один лист",
        slug: "single",
      },
      {
        name: "Много листов",
        slug: "several",
      },
    ],

    selectedScaleOptions: null,
    scaleOptions: [],

    /* LOADERS */
    applyLoader: false,
  }),
  computed: {
    testSettings() {
      return this.$store.getters["test/settings"];
    },
    scales() {
      return this.testSettings.scales;
    },
    oneSheetExcel() {
      return this.testSettings.oneSheetExcel;
    },
    points() {
      return this.testSettings.points;
    },
    defaultPoints() {
      return this.testSettings.defaultPoints;
    },
    defaultScales() {
      return this.testSettings.defaultScales;
    },
    tasks() {
      return this.testSettings.tasks;
    },
    responseTime() {
      return this.testSettings.responseTime;
    },
    answersToTasks() {
      return this.testSettings.answersToTasks;
    },
    rawPointForTasks() {
      return this.testSettings.rawPointForTasks;
    },
    matchPercentage() {
      return this.testSettings.matchPercentage;
    },
    respondentData() {
      return this.testSettings.respondentData;
    },
    respondentViewSettings() {
      return this.testSettings.respondentViewSettings;
    },
    respondentAdditionalQuestionnaire() {
      return this.testSettings.respondentAdditionalQuestionnaire;
    },

    computedScales() {
      if(!this.scaleOptions.length) return;
      return this.scaleOptions.map((option) => {
        option.selected = this.scales?.includes(option.number);

        return option;
      });
    }
  },

  watch: {
    oneSheetExcel(newOneSheetExcel) {
      this.excelStructureOption = newOneSheetExcel
          ? this.excelStructureOptions[0]
          : this.excelStructureOptions[1];
    },
    excelStructureOption(newExcelStructureOption) {
      switch (newExcelStructureOption.slug) {
        case "several":
          this.setExcelStructureOption({oneSheetExcel: false});
          break;

        case "single":
          this.setExcelStructureOption({oneSheetExcel: true});
          break;

        default:
          break;
      }
    },
    selectedScaleOptions(newSelectedScaleOptions) {
      this.setScales({scales: newSelectedScaleOptions});
    },
    points(newPoints) {
      if (!newPoints) {
        this.updateDefaultPoints(true);
        this.updateDefaultScales(true);
      }
    },
    tasks(newTasks) {
      if (!newTasks) {
        this.setResponseTime(false);
        this.setAnswersToTasks(false);
        this.setRawPointForTasks(false);
        this.setMatchPercentage(false);
      }
    },
    respondentData(newRespondentData) {
      if (!newRespondentData) {
        this.setRespondentViewSettings(false);
        this.setRespondentAdditionalQuestionnaire(false);
      }
    },
  },
  methods: {
    ...mapActions(["fetchTestScales"]),
    ...mapActions("test", ["saveExportExcelSettings", "setTestsSettingsStore"]),

    /* GETTERS */
    /* SETTERS */
    setPoints(points) {
      this.setTestsSettingsStore({
        points,
      });
    },
    setScales({scales}) {


      this.setTestsSettingsStore({
        scales: scales.map((scale) => scale.number),
      });
    },
    setTasks(tasks) {


      this.setTestsSettingsStore({tasks});
    },
    setResponseTime(responseTime) {


      this.setTestsSettingsStore({responseTime});
    },
    setAnswersToTasks(answersToTasks) {
      this.setTestsSettingsStore({
        answersToTasks,
      });
    },
    setRawPointForTasks(rawPointForTasks) {
      this.setTestsSettingsStore({
        rawPointForTasks,
      });
    },
    setMatchPercentage(matchPercentage) {
      this.setTestsSettingsStore({
        matchPercentage,
      });
    },
    setRespondentData(respondentData) {
      this.setTestsSettingsStore({
        respondentData,
      });
    },
    setRespondentViewSettings(respondentViewSettings) {
      this.setTestsSettingsStore({
        respondentViewSettings,
      });
    },
    setRespondentAdditionalQuestionnaire(respondentAdditionalQuestionnaire) {
      this.setTestsSettingsStore({
        respondentAdditionalQuestionnaire,
      });
    },
    setExcelStructureOption({oneSheetExcel}) {
      this.setTestsSettingsStore({oneSheetExcel});
    },

    /* HANDLERS */
    updateDefaultPoints(defaultPoints) {
      this.setTestsSettingsStore({
        defaultPoints,
      });
    },
    updateDefaultScales(defaultScales) {
      this.setTestsSettingsStore({
        defaultScales,
      });
    },
    updateResponseTime({value}) {
      if (this.testSettings.tasks) {
        this.setResponseTime(value);
      }
    },
    updateAnswersToTasks({value}) {
      if (this.testSettings.tasks) {
        this.setAnswersToTasks(value);
      }
    },
    updateRawPointForTasks({value}) {
      if (this.testSettings.tasks) {
        this.setRawPointForTasks(value);
      }
    },
    updateMatchPercentage({value}) {
      if (this.testSettings.tasks) {
        this.setMatchPercentage(value);
      }
    },
    updateRespondentViewSettings({value}) {
      if (this.testSettings.respondentData) {
        this.setRespondentViewSettings(value);
      }
    },
    updateRespondentAdditionalQuestionnaire({value}) {
      if (this.testSettings.respondentData) {
        this.setRespondentAdditionalQuestionnaire(value);
      }
    },
    updateSelectedScaleOptions({options}) {
      this.selectedScaleOptions = options;
    },
    async apply() {


      this.applyLoader = true;

      await this.saveExportExcelSettings({
        uuidTest: this.$route.params.uuid,
        uuidSettings: this.testSettings.uuid,
        payload: outputSettingsAdapter(this.testSettings),
      });

      this.applyLoader = false;
    },
  },

  async created() {
    this.scaleOptions = inputTestScalesAdapter(
        await this.fetchTestScales({
          uuidTest: this.$route.params.uuid,
        })
    );
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.tests-library--settings-export-excel {
  background: #ffffff;
  border: 1px solid #e7e8f0;
  border-radius: 8px;

  &__header,
  &__footer {
    padding: 22px 20px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    &--title {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }

    &--select {
      margin-left: 10px;
      min-width: 130px !important;
      width: max-content;

      .mt-solid__wrapper {
        width: 150px;
        background: #ffffff;

        .mt-solid__selected {
          & > span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #252733;
            white-space: nowrap;
          }
        }

        .mt-solid__list {
          .mt-solid__list-item {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #252733;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
  }

  &__apply {
    width: 125px !important;
    height: 33px !important;
    border-radius: 8px !important;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    user-select: none;
  }

  &__row {
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 30px 22px;
    border-bottom: 1.5px solid #dfe0eb;

    &-checkbox {
      cursor: pointer;
      min-width: 20px;
      min-height: 20px;

      &_disabled {
        border: 2px solid #dfe0eb !important;
        cursor: default !important;
      }
    }

    &-title {
      margin: 0px 15px;
      cursor: pointer;
      user-select: none;

      &_disabled {
        color: #dfe0eb !important;
        cursor: default !important;
      }
    }

    &--col-1 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }

    &--col-2 {
      &-option {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }

        &--title {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
  }

  &__units {
  }

  &__unit {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__scales-list {
    &--select {
    }
  }
}
</style>