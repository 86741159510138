import api from "@/network";

export default {
    state: {
        administrator: {
            branches: [],
            formSettings: []
        },
    },

    getters: {
        administrator(state) {
            return state.administrator;
        },

        administratorBranches(state) {
            console.log(state.administrator)
            return state.administrator.branches || [];
        },
    },

    actions: {
        async fetchAdministrator({commit}, payload = {}) {
            const response = await api.get(`admin/user/${payload.uuid}`);

            commit('updateAdministrator', response?.data?.data);
            return response
        },

        async setAdministratorStore({commit, state}, payload = {}) {
            commit('updateAdministrator', {...state.administrator, ...payload});
        },

        async addAdministratorBranchStore({commit, state}, payload = {}) {
            let branches = state.administrator.branches;

            branches.push(payload);

            commit('updateAdministratorBranches', branches);
        },

        async setRoleOfAdministratorBranchStore({commit, state}, payload = {}) {


            let branches = state.administrator.branches;

            commit(
                'updateAdministratorBranches',
                branches.map(
                    (branch) => {
                        if (branch.branch.uuid === payload.branch.uuid) {
                            return {
                                ...branch,

                                role: payload.role,
                            }
                        }

                        return branch;
                    }
                )
            );
        },

        async deleteAdministratorBranchStore({commit, state}, payload = {}) {
            let branches = state.administrator.branches;
            if (branches.length > 1) {
                commit(
                    'updateAdministratorBranches',

                    branches.filter(
                        (branch) => {


                            return branch.branch.uuid !== payload.branch.branch.uuid;
                        }
                    )
                );
            }
        },

        async resetAdministratorStore({commit}) {
            commit(
                'updateAdministrator',

                {
                    branches: [],
                    formSettings: [],
                }
            );
        },

        async createAdministrator({commit, state}, administrator) {
            let data = administrator;

            if (!!state.administrator.branches) data['branches'] = state.administrator.branches

            const response = await api.post('admin/user', data);
            commit('updateAdministrator', response?.data?.data);

            return response;
        },

        async uploadAdministrator({state}, payload = {}) {
            let data = payload.administrator
            if (!!state.administrator.branches) data['branches'] = state.administrator.branches
            return await api.put(`admin/user/${payload.uuid}`, data);
        },

        async getBillings(context, payload) {
            let url = 'admin/billing';
            if (payload.page > 1) {
                url = `${url}?page=${payload.page}`
            }
            if (payload.perpage !== 0) {
                if (payload.page > 1) {
                    url = `${url}&per_page=${payload.perpage}`
                } else {
                    url = `${url}?per_page=${payload.perpage}`
                }
            }

            url = `${url}&settings=${JSON.stringify(payload.settings)}`;

            return await api.get(url);
        }
    },

    mutations: {
        updateAdministrator(state, administrator) {
            state.administrator = administrator;
        },

        updateAdministratorBranches(state, branches) {
            state.administrator.branches = branches;
        }
    },
}