<script
    setup
    lang="ts"
>
import { onMounted, Ref } from "vue";

const props = defineProps({
  top: {
    type: Boolean,
    default: false
  },
  bottom: {
    type: Boolean,
    default: true
  },
  right: {
    type: Boolean,
    default: false
  },
  dense: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  oneLiner: {
    type: Boolean,
    default: false
  }
})

const root: Ref<HTMLElement | null> = ref(null)
const headerRef: Ref<HTMLElement | null> = ref(null)
const bodyRef: Ref<HTMLElement | null> = ref(null)
const isMouseOver = ref(false)
const positions = ref({
  top: "0",
  left: "0"
})
const triangleHeight = 11

const computedBodyStyles = computed(() => {
  if (!isMouseOver.value) return positions.value;

  const scrollTop = window.scrollY || document.documentElement.scrollTop
  const rootRect = root.value?.getBoundingClientRect()
  const headerRect = headerRef.value?.getBoundingClientRect()
  const bodyRect = bodyRef.value?.getBoundingClientRect()

  if (!rootRect || !headerRect || !bodyRect) return;

  const top = props.right ?
      `${ (rootRect.top + scrollTop) - (bodyRect.height / 2) + (rootRect.height / 2) }px` :
      props.top ?
          `${ (rootRect.top + scrollTop) - bodyRect.height - triangleHeight }px` :
          `${ rootRect.top + scrollTop + headerRect.height + triangleHeight }px`;

  const left = props.right ?
      `${ headerRect.left + headerRect.width + triangleHeight }px` :
      `${ headerRect.left - (bodyRect.width / 2) + (headerRect.width / 2) }px`

  return positions.value = { top, left }
})

onMounted(() => {
  headerRef.value?.addEventListener('mouseenter', () => {
    isMouseOver.value = true
  })

  headerRef.value?.addEventListener('mouseleave', () => {
    isMouseOver.value = false
  })
})
</script>

<template>
  <div
      ref="root"
      :class="[
        'tooltip',
        {
          'tooltip_dense': dense,
          'tooltip_disabled': disabled,
        }
      ]"
  >
    <div
        ref="headerRef"
        :class="[
          'tooltip__header',
          {
            'tooltip__header_disabled': disabled,
          }
        ]"
    >
      <slot name="activator"/>
    </div>

    <template v-if="isMouseOver">
      <teleport to="body">
        <div
            ref="bodyRef"
            :class="[
            'tooltip__body',
            {
              'tooltip__body--one-liner': oneLiner,
              'tooltip__body--visible': isMouseOver,
              'tooltip__body--bottom': !props.top && props.bottom,
              'tooltip__body--right': props.right
            }
          ]"
            :style="computedBodyStyles"
        >
          <slot/>
        </div>
      </teleport>
    </template>
  </div>
</template>

<style
    lang="scss"
    src="./AppTooltip.scss"
/>