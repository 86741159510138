<template>
  <div class="support__container">
    <label class="page-title">Обращение в техническую поддержку</label>
    <div class="input__container">
      <label class="input__label">Тема <span class="required">*</span></label>
      <mt-input
          class="input"
          v-model="title"
          :error="invalidTitle"
          ref="titleInp"
      />
      <span
          class="error-message"
          v-show="invalidTitle"
      >
        Заполните поле “Тема”
      </span>
    </div>
    <div class="input__container">
      <label class="input__label">Текст <span class="required">*</span></label>
      <mt-textarea
          v-model="description"
          ref="descInp"
          :error="invalidDesc"
          class="input textarea"
      />
      <span
          class="error-message"
          v-show="invalidDesc"
      >
        Заполните поле “Текст”
      </span>
    </div>

    <div class="attached">
      <div
          class="attached__title"
          v-show="files.length"
      >
        <strong class="text">Прикрепленные файлы</strong>
        <img
            @click="showAttach = !showAttach"
            :class="['arrow', showAttach ? 'isOpen' : '']"
            src="@/assets/img/svg/arrow_up.svg"
            alt="arrow"
        >
      </div>

      <div
          class="files"
          v-show="files.length && showAttach"
      >
        <div
            class="files__item"
            v-for="file in files"
            :key="file.name"
        >
          <span class="file-name">{{ file.name }}</span>
          <mt-icon
              v-if="file.loading"
              :name="'preloader'"
              :fill="'#00788c'"
              :preloader="true"
          />
          <img
              v-if="!file.loading"
              @click="deleteFile(file)"
              class="close"
              src="@/assets/img/png/close.png"
              alt="close"
          />
        </div>
      </div>

      <label class="add-file__btn">
        <input
            hidden
            type="file"
            id="file"
            ref="file"
            @change="handleFileUpload()"
        />
        <img
            class="add-file__icon"
            src="@/assets/img/svg/link.svg"
            alt="link"
        />
        Прикрепить файл
      </label>

    </div>
    <div class="btn-container">
      <mt-button
          :class="'mt-button_light-green'"
          @click="deleteAppeal"
      >
        Назад
      </mt-button>
      <mt-button @click="sendAppeal">Отправить</mt-button>
    </div>
  </div>
</template>

<script>
import mtInput from "@/components/UI/mtInput/mtInput.vue";
import mtTextarea from "@/components/UI/mtTextarea/index.vue";
import mtButton from "@/components/UI/mtButton/index.vue";
import mtIcon from "@/components/UI/mtIcon/mtIcon.vue";
import { mapActions } from "vuex";

export default {

  components: {
    mtInput,
    mtTextarea,
    mtButton,
    mtIcon
  },
  data() {
    return {
      uuid: '',
      title: '',
      description: '',
      isInvalidTitle: false,
      isInvalidDesc: false,
      showAttach: false,
      invalidTitle: false,
      invalidDesc: false,
      file: {
        type: File
      },
      files: []
    }
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['fetchPlan']),

    createAppeal() {
      this.$workspaceApi.support.createAppeal()
          .then(res => {
            this.uuid = res.data.uuid;
          })
    },

    async sendAppeal() {
      this.$refs.titleInp.setTouched(true);
      this.$refs.descInp.setTouched(true);
      this.checkForm();
      if (this.isInvalidTitle || this.isInvalidDesc) {
        return;
      }
      await this.$workspaceApi.support.sendAppeal(this.uuid, {title: this.title, description: this.description})
          .then(res => {
            this.title = '';
            this.description = '';
            this.file = null;
            this.files = [];
            this.$toast.success("Письмо успешно отправлено!")
            this.createAppeal();
            this.$refs.titleInp.setTouched(false);
            this.$refs.descInp.setTouched(false);
          })
    },
    checkForm() {
      this.invalidTitle = !this.title.trim().length && this.$refs.titleInp?.touched;
      this.invalidDesc = !this.description.trim().length && this.$refs.descInp?.touched;
    },
    goBack() {
      this.$router.push('/');
    },
    async deleteAppeal() {
      await this.$workspaceApi.support.deleteAppeal(this.uuid)
          .then(res => {
            this.goBack();
          })
    },
    async uploadFile(fileId) {
      await this.$workspaceApi.support.uploadFiles(this.uuid, this.file)
          .then(res => {

            this.files.forEach(x => {
              if (x.id === fileId) {
                x.uuid = res.data?.uuid ?? '';
                x.loading = false;
              }
            })
          })
    },

    deleteFile(file) {
      this.files = this.files.filter(x => x.uuid !== file.uuid);
      this.$workspaceApi.support.deleteFile(file.uuid)
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      const id = Date.now();
      this.files.push({id, name: this.file.name, loading: true, uuid: ''});
      this.uploadFile(id);
      this.showAttach = true;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    }

  },
  mounted() {
    this.createAppeal();
    this.scrollToTop()
    this.showAttach = window.innerWidth >= 576;
    window.addEventListener('resize', (event) => {
      this.showAttach = window.innerWidth >= 576;
    })
  },
  watch: {
    description() {
      this.checkForm()
    },
    title() {
      this.checkForm()
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
.support__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 1px solid #DFE0EB;
  border-radius: 8px;
  padding: 32px;

}

.input {
  max-width: 327px;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .error-message {
      color: rgb(194 64 32);
      margin-top: 3px;
      font-size: 12px;
      min-height: 14px;
    }
  }

  &__label {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #7C869D;
    display: flex;
    align-items: center;
    gap: 3px;

    .required {
      color: red;
    }
  }

  &.textarea {
    max-width: 676px;

  }
}

.attached {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .text {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #252733;
    }

    .arrow {
      display: none;
    }

  }

  .files {
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    background: #F0F1F7;
    border-radius: 12px;
    padding: 10px 15px;
    overflow: hidden;
    margin-bottom: 22px;

    &__item {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-decoration-line: underline;
      color: #252733;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .file-name {
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 90%;
      }

      .close {
        width: 14px;
        height: 14px;
        opacity: 0.7;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .add-file {
    &__btn {
      display: flex;
      align-items: center;
      padding: 5px 0;
      cursor: pointer;
      gap: 17px;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #00788C;
      width: max-content;


    }

    &__icon {
      width: 24px;
      height: 24px;
    }
  }
}

.page-title {
  display: none;
}

.btn-container {
  display: flex;
  align-items: center;
  gap: 10px;
}


@media screen and (max-width: 576px) {

  .header__page-info__details {
    display: none;
  }
  .support__container {
    border: 0;
    padding: 0;
  }

  .input {
    width: 100%;
    max-width: unset;
  }
  .page-title {
    display: flex;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #252733;
  }
  .btn-container {

    .mt-button_green {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 13px 25px;
      width: 100%;
      height: 50px;
      background: #00788C;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 8px;

      &.mt-button_light-green {
        display: none;
      }
    }
  }
  .attached {

    &__title {
      display: flex;
      justify-content: space-between;


      .arrow {
        display: flex;
        width: 24px;
        height: 24px;
        transform: rotate(180deg);
        transition: 0.5s;

        &.isOpen {
          transform: rotate(0deg);
        }
      }

    }
  }
}
</style>